import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { RecieveMsg, SendMsg } from './Massageboxes'

export const ChatWindow = (props) => {
    const LoginInfo = useSelector(state => state.state.loginInfo)


    useEffect(() => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    }, [props])

    return (
        <div className="msg">
            {props.chat.map(function (item, index) {
                if (LoginInfo.userId === item.UserId)
                    return <SendMsg msg={item.Text} date={item.CreatedDate} key={index} IsSeen={item.IsSeen} />
                else
                    return <RecieveMsg msg={item.Text} date={item.CreatedDate} key={index} IsSeen={item.IsSeen} />

            })}
        </div>
    )
};

export default ChatWindow;
