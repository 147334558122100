import React from 'react';
import Grid from '@mui/material/Grid';
import NumberWithCommas from './NumberWithCommas'
import CheckIcon from '@mui/icons-material/Check';
import { Dialog, Box, Container } from '@mui/material';
import { Button } from './inputs/Button';
import { ApproveOffer, CreateChat } from '../config';
import { PostService } from '../services/Fetch';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setMsgApprove, setOfferCount } from '../redux/reducer'
import { AllOffersForExchange } from '../config'
import { GetService } from '../services/Fetch'

export default function UserOfferContent(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [handleClose, setHandleClose] = React.useState(false)

  const FetchApprove = async (rest) => {
    let response = await PostService(ApproveOffer, { Id: rest })
    if (response?.Data) {
      FetchGetAllOffersForExchange()
      FetchChat()
    }
  }

  const FetchGetAllOffersForExchange = async () => {
    let response = await GetService(AllOffersForExchange)
    dispatch(setOfferCount(response.data.Data))
  }


  const FetchChat = async () => {
    let response = await PostService(CreateChat, { To: props.data.UserId })
    dispatch(setMsgApprove({
      exist: true,
      type: props.data.Type === 1 ? 'خرید' : 'فروش',
      amount: NumberWithCommas(props.data.Amount),
      unitPrice: NumberWithCommas(props.data.UnitPrice),
    }))
    navigate("/messages/detail", {
      state: {
        ChatId: response.Data.Id,
        targetUserId: props.data.UserId,
        DisplayName: props.data.UserFullName
      }
    });
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 1 }}
        spacing={2}
        className={"user--ofer--content "}
      >
        <Grid
          item
          xs={4}
        >
          <Grid
            container
            direction="row"
            alignItems="self-end"
            className="chat--icon"
            flexWrap='nowrap'
          >
            <span style={{ 'whiteSpace': 'nowrap', 'textOverflow': 'ellipsis', 'overflow': 'hidden' }}>
              {props.data.UserFullName}
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ textAlign: 'right' }}
          className="value"
        >
          {NumberWithCommas(props.data.Amount)} $
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ textAlign: 'left', direction: 'rtl' }}
        >

          {NumberWithCommas(props.data.UnitPrice)} T

        </Grid>
        <Grid
          item
          xs={2}
          sx={{ textAlign: 'right' }}
        >
          <CheckIcon
            fontSize='small'

            onClick={() => {
              if (props.data.AcceptedByExchangeId !== props.exid) {
                props.data.Type === 1 ? setHandleClose(true) : setHandleClose(true)
              }
            }}

            sx={props.data.AcceptedByExchangeId !== props.exid ? { cursor: 'pointer' } : { backgroundColor: '#00BC1E !important', fill: '#fff !important' }}
          />
        </Grid>

      </Grid>
      <Dialog open={handleClose} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'unset' } }}>
        <Container>
          <Grid bgcolor='#F6F6F6' border='1px solid #d3d3d3' borderRadius='15px' padding='48px 24px' sx={{ mt: 4 }}>
            <Box textAlign='center' sx={{ mb: 4 }}>
              آیا از پذیرش این پیشنهاد مطمن هستید؟
            </Box>
            <Box>
              <Grid display='flex' justifyContent='center' sx={{ mt: 5 }}>
                <Button onClick={() => FetchApprove(props.data.Id)} label='بله' type="submit" variant="contained" color="success" fullWidth={false} sx={{ borderRadius: '5px', width: '90px', margin: '0 10px !important', padding: '6px 0 !important' }} className="btn" />
                <Button onClick={() => setHandleClose(false)} label='خیر' type="submit" variant="contained" color="success" fullWidth={false} sx={{ borderRadius: '5px', width: '90px', margin: '0 10px !important', padding: '6px 0 !important', backgroundColor: '#D70000 !important' }} className="btn" />
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Dialog>
    </>
  )
}

