import { Controller } from "react-hook-form";
import TextInput from "../inputs/TextInput"
import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const EmailControl = ({
  name,
  label = 'ایمیل',
  defaultValue = '',
  control,

}) => {
  // const customClassName = 'TextField ' + className
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextInput
          label={label}
          onChange={onChange}
          value={value}
          error={error}
          autoComplete="off"
          className="align--left"
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{padding: '0 0.5rem'}} position="end">
                <EmailOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      rules={{ required: 'وارد کردن ایمیل اجباری است ' }}
    />

  )
}

export default EmailControl