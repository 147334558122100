import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { PostService } from '../services/Fetch'
import { LoginURL } from '../config'
import { useDispatch } from 'react-redux'
import { setLoginInfo, setSnackBarStatus } from '../redux/reducer'
import Container from '../components/Container'
import Cookies from 'js-cookie'
import { Grid, Box } from "@mui/material";
import SetPageTitle from "../utils/SetPageTitle";
import { Button, NavButton } from "../components/inputs/Button"
import EmailControl from "../components/controles/EmailControl"
import PasswordControl from "../components/controles/PasswordControl"
import { NavButtonExchangeRegister } from "../components/inputs/Button";

export default function Login(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [userEmail, setUserEmail] = React.useState()

    const forgotPassHandler = () => {
        navigate("/forgot", {            
            state: {
                email : userEmail
            }
        }) 
    }
    const emailGetting = (data) => {
        setUserEmail(data.u)
    }

    const { handleSubmit, control } = useForm({
        defaultValues: {}
    });
    const onSubmit = async (data) => {
        let response = await PostService(LoginURL, data, false)
        if (response) {
            dispatch(setLoginInfo(response))
            dispatch(setSnackBarStatus({ state: true, type: 'success', msg: 'ورود با موفقیت انجام شد' }))
            Cookies.set('loginData', JSON.stringify(response), { expires: 14 }) // 1/24 = 1 hour
            navigate(`/`)
        } else {
            dispatch(setSnackBarStatus({ state: true, type: 'error', msg: 'نام کاربری یا رمز عبور اشتباه است' }))
        }
    };

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])


    return (
        <>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(emailGetting)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} >
                            <EmailControl name='u' control={control} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <PasswordControl name='p' control={control} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: 'flex', justifyContent: 'flex-end'}} >
                            <Box onClick={forgotPassHandler} sx={{my: 2 ,color: '#426BA2', display: 'inline', fontSize: '1rem', cursor: 'pointer', textAlign: 'right'}}>فراموشی کلمه عبور</Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Button label='ورود' type='submit' />
                            <NavButton label='ثبت نام' to='/register' variant="outlined" />
                        </Grid>
                    </Grid>
                </form>
            </Container>

            <fieldset style={{ textAlign: 'center', border: 'none', borderTop: 'solid 1px #D3D3D3', padding: '0 30px' }}>
                <legend>
                    مختص صرافها
                </legend>
                <NavButtonExchangeRegister label='عضویت در کانون صرافها' onClick={()=> window.location.replace('https://irguild.com/membershipform')} variant="outlined"
                    sx={{ mt: 3, backgroundColor: "#F5F5F5", borderColor: 'transparent !important' }}
                />

            </fieldset>
        </>

    )
}  