import axios from "axios";
import { setLoadingIncrease, setLoadingDecrease } from '../redux/reducer'
import { store } from '../redux/store'
import Cookies from 'js-cookie'

export const GetService = async (baseURL, auth = true) => {
  const LoginData = Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null;
  let headers = {
    "Content-Type": "application/json",
    "Authorization": auth ? 'Bearer ' + LoginData?.accessToken : null
  }
  // store.dispatch(setIsLoading(true))
  store.dispatch(setLoadingIncrease())
  return await axios.get(baseURL, { headers })
    .then((response) => {
      // store.dispatch(setIsLoading(false))
      store.dispatch(setLoadingDecrease())
      if (response) {
        return {
          ...response,
          success: true
        };
      }
      return response
    }).catch(function (error) {
      // handle error
      // store.dispatch(setIsLoading(false))
      store.dispatch(setLoadingDecrease())
      console.log(error);
      console.log('URL ERROR : ' + baseURL);
    })
}


export const PostService = async (url, body, auth = true) => {
  const LoginData = Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null;
  let headers = {
    "Content-Type": "application/json",
    "Authorization": auth ? 'Bearer ' + LoginData.accessToken : null
  }
  store.dispatch(setLoadingIncrease())
  // store.dispatch(setIsLoading(true))
  return await axios.post(url, body, { headers })
    .then((response) => {
      // store.dispatch(setIsLoading(false))
      store.dispatch(setLoadingDecrease())
      return response.data;
    }).catch(function (error) {
      // handle error
      // store.dispatch(setIsLoading(false))
      store.dispatch(setLoadingDecrease())
      console.log(error);
      console.log('URL ERROR : ' + url);
    });
};