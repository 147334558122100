
import React, { useEffect } from "react";
import Container from '../components/Container'
import SetPageTitle from "../utils/SetPageTitle";
import { useSelector } from 'react-redux'
import Box from "@mui/material/Box";
import { PostService } from "../services/Fetch";
import { SeenNotification } from "../config";
import { NotificationService } from '../services/NotificationService'


export default function Notifications(props) {
    const notifications = useSelector(state => state.state.notifications)

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    const openLink = async (link, Id) => {
        let response = await PostService(SeenNotification, [Id])
        window.location.replace(link)
    }

    useEffect(() => {
        NotificationService()
    }, [])

    return (
        <Container>

            {notifications ?
                notifications.map(function (item, index) {
                    return (
                        <Box key={index} className={'notofication--box ' + (!item.IsSeen ? 'not--seen' : '')} onClick={() => openLink(item.LinkUrl, item.Id)}>
                            <img src={item.ImageUrl === null || item.ImageUrl === "" || item.ImageUrl === undefined ? 'https://irguild.com/Portals/0/no-image-notif.png' : 'https://irguild.com' + item.ImageUrl} alt='notification'/>
                            <span>{item.Title}</span>
                        </Box>

                    )
                })
                :
                null
            }

        </Container>
    )
}  