import { Box } from "@mui/material";

export default function SpecialExchangeBox(props) {
  return (
    <Box className='exchange--wrap special--warp' sx={{ borderRadius: '10px', border: '1px solid #DDD8B0', overflow: 'hidden', mx: 1 }}>
      <Box className='' sx={{ textAlign: 'center', backgroundColor: '#1976d2', color: '#fff', padding: '10px', fontWeight: '600' }}>
        {props.title}
      </Box>
      <Box className='exchange--items special--items'>
        {props.children}
      </Box>
    </Box>

  )

}



