
import React, { useEffect } from "react";
import Container from '../components/Container'
import { useNavigate } from 'react-router-dom';
import { Controller, useForm, reset } from "react-hook-form";
import TextInput from "../components/inputs/TextInput";
import { Button } from "../components/inputs/Button";
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SetPageTitle from "../utils/SetPageTitle";
import { PostService, GetService } from '../services/Fetch'
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { GetExchangeInfo, SubmitBuyPrice, GetAllRates } from "../config";
import FormHelperText from '@mui/material/FormHelperText';
import NumberWithCommas from '../components/NumberWithCommas'

export default function BuyPrice(props) {
    const navigate = useNavigate()
    const [snackbar, setSnackbar] = React.useState(false)
    const { handleSubmit, control, watch } = useForm({});
    const [exChangeInfo, setExChangeInfo] = React.useState();
    const [allRates, setAllRates] = React.useState();
    const [subBuyPrice, setSubBuyPrice] = React.useState()

    const onSubmit = async (data) => {
        FetchSubmitBuyPrice(exChangeInfo, data.RateId, data.BuyPrice)
        setSnackbar(true)
        setTimeout(() => {
            navigate('/')
        }, 1000)
    }
    const FetchExchangeInfo = async () => {
        let response = await GetService(GetExchangeInfo)
        let temp = response.data
        if (temp?.Data) {
            setExChangeInfo(temp.Data.Id)
        }
    }
    const FetchAllRates = async () => {
        let response = await GetService(GetAllRates)
        let temp = response.data
        if (temp?.Data) {
            setAllRates(temp.Data)
        }
    }
    const FetchSubmitBuyPrice = async (exid, rateid, price) => {
        let response = await PostService(SubmitBuyPrice, { ExchangeId: exid, RateId: rateid, BuyPrice: price })
        if (response?.Data) {
            setSubBuyPrice(response.Data)
        }
    }

    useEffect(() => {
        FetchAllRates()
        FetchExchangeInfo()
    }, [])

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    const renderDropDown = () => {
        const dropDownItems = allRates.map((content, i) => <MenuItem key={i} value={content.Id}>{content.Title}</MenuItem>)
        return (dropDownItems)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setSnackbar(false);
        }

    };

    const inputPrice = watch('BuyPrice')

    return (
        <>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ mt: 4 }}>
                        <Grid item xs={12} sx={{ mb: 2, width: '100%' }}>
                            <Controller
                                name="BuyPrice"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Grid>
                                        <TextInput
                                            label='قیمت خرید'
                                            value={value}
                                            type="number"
                                            autoComplete="off"
                                            onChange={onChange}
                                            error={error}
                                            className="TextField align--left"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">تومان</InputAdornment>
                                            }}

                                        />

                                    </Grid>
                                )}
                                rules={{ required: true }}
                            />
                            {inputPrice &&
                                <FormHelperText sx={{
                                    direction: 'rtl',
                                    textAlign: 'right',
                                    paddingRight: '80px'
                                }}
                                >
                                    {NumberWithCommas(inputPrice)}
                                </FormHelperText>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="RateId"
                                control={control}
                                defaultValue="1"
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Grid>
                                        <FormControl fullWidth>
                                            <InputLabel id="price--label">بازه قیمت</InputLabel>
                                            <Select
                                                labelId='price--label'
                                                value={value}
                                                label="بازه قیمت"
                                                onChange={onChange}
                                                error={error}
                                                fullWidth
                                            >
                                                {allRates == undefined ? null : renderDropDown()}
                                            </Select>
                                        </FormControl>
                                        <Box sx={{ width: '100%', height: '200px' }}>

                                        </Box>
                                    </Grid>
                                )}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid>
                            <Button label='ثبت' type="submit" variant="contained" color="primary" fullWidth className="btn" />
                        </Grid>
                    </Box>
                </form>
            </Container>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={snackbar}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">ثبت قیمت با موفقیت انجام شد.</Alert>
            </Snackbar>
        </>
    )
}  
