


import TextField from '@mui/material/TextField';

const TextInput = ({
  label,
  variant = 'outlined',
  type = 'text',
  size = 'large',
  onChange,
  value,
  error,
  autoComplete = 'false',
  className,
  InputProps
}) => {
  const customClassName = 'TextField ' + className
  return (
    <TextField
      label={label}
      fullWidth
      variant={variant}
      size={size}
      type={type}
      autoComplete={autoComplete}
      className={customClassName}
      InputProps={InputProps}
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error ? error.message : null}
    />

  )
}

export default TextInput