
import React, { useEffect } from "react";
import Container from '../components/Container'
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/inputs/Button";
import Box from '@mui/material/Box';
import { Grid, Switch, Dialog } from "@mui/material";
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SetPageTitle from "../utils/SetPageTitle";
import { TableContainer, Table, TableBody, TableHead, TableCell, TableRow } from "@mui/material";
import { GetLastSpecialBuy, GetExchangeInfo, SetNewHistoryAndUpdateSpecialOffer, GetExchangeRolesUsers, SendSpecialBuyNotificationToExchangeUsers, EditSpecialBuy } from '../config'
import { PostService, GetService } from '../services/Fetch'
import NumberWithCommas from "../components/NumberWithCommas";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Controller, useForm } from "react-hook-form";



export default function SpecialBuyPrice(props) {
    const navigate = useNavigate()
    const [userId, setUserId] = React.useState()
    const [handleActive, setHandleActive] = React.useState(true);
    const [handleColor, setHandleColor] = React.useState('#426BA2');
    const [exChangeInfo, setExChangeInfo] = React.useState();
    const [exChangeInfoName, setExChangeInfoName] = React.useState();
    const [getSpecialBuy, setGetSpecialBuy] = React.useState();
    const [status, setStatus] = React.useState()
    const [sendStatus, setSendStatus] = React.useState()
    const { handleSubmit, control } = useForm({});
    const [snackbar, setSnackbar] = React.useState(false)
    const [handleDialog, setHandleDialog] = React.useState(false)
    const [exchangeList, setExchangeList] = React.useState()
    const [editBuy, setEditBuy] = React.useState()

    const handleActiveing = (event) => {
        setHandleActive(event.target.checked)
        EditSpecialBuyActive(getSpecialBuy.Id, !handleActive)
        setHandleColor(!handleActive ? '#426BA2' : '#b0b0b0')
    }
    const onSubmit = async (data) => {
        sendStatusandPrice(data.SpecialHistoryDealerName, 1)
        setHandleDialog(false)
        setSnackbar(true)
        setTimeout(() => {
            setStatus(3)
            navigate('/profile/exchange-offers')
        }, 3000)
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setSnackbar(false);
        }

    };
    const FetchExchangeInfo = async () => {
        let response = await GetService(GetExchangeInfo)
        let temp = await response.data
        if (temp?.Data) {
            setExChangeInfo(temp.Data.Id)
            setExChangeInfoName(temp.Data.Name)
            setUserId(temp.Data.UserId)
        }
    }
    const FetchExchangeList = async () => {
        let response = await PostService(GetExchangeRolesUsers)
        if (response?.Data) {
            setExchangeList(response.Data)
        }
    }
    const FetchSpecialBuy = async (exid) => {
        let response = await PostService(GetLastSpecialBuy, { ExchangeId: exid, })
        let temp = await response
        if (temp?.Data) {
            setGetSpecialBuy(response.Data)
            if (response.Data.Status !== 0) {
                navigate('/profile/exchange-offers')
            }
        }
    }
    const EditSpecialBuyActive = async (id, status) => {
        let response = await PostService(EditSpecialBuy, { SpecialBuyActive: status, SpecialBuyId: id })
        let temp = await response
        if (temp?.Data) {
            setEditBuy(response.Data)
        }
    }
    const FetchStatus = async (eId, id, shUserid, shPrice, shuPrice, shStartdate, shEnddate, shStatus, shDealername, which, spOfferactive) => {
        let response = await PostService(SetNewHistoryAndUpdateSpecialOffer,
            {
                ExchangeId: eId,
                SpecialHistoryUserId: "" + shUserid,
                SpecialOfferId: id,
                SpecialHistoryPrice: shPrice,
                SpecialHistoryUnitPrice: shuPrice,
                SpecialHistoryStartDate: shStartdate,
                SpecialHistoryEndDate: shEnddate,
                SpecialHistoryStatus: shStatus,
                SpecialHistoryDealerName: shDealername,
                WhichSpecialOffer: which,
                SpecialOfferActive: spOfferactive
            })
        if (response?.Data) {
            setSendStatus(response.Data)
        }
    }

    const sendStatusandPrice = (dealName, historyStatus) => {
        const { ExchangeId, Id, EndDate, SpecialOfferPrice, SpecialOfferUnitPrice, StartDate, Description } = getSpecialBuy
        FetchStatus(ExchangeId, Id, userId, SpecialOfferPrice, SpecialOfferUnitPrice, StartDate, EndDate, historyStatus, dealName, 'SpecialBuy', handleActive)
    }

    useEffect(() => {
        FetchExchangeInfo()
        FetchExchangeList()
    }, [])

    useEffect(() => {
        FetchSpecialBuy(exChangeInfo)
    }, [status, exChangeInfo])

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])
    function createData(
        title,
        data
    ) {
        return { title, data }
    }
    const renderDatas = (rest) => {
        const data = [
            createData('تاریخ ثبت', moment(moment(rest.StartGregorianDate).format('YYYY-MM-DD'))._i),
            createData('تاریخ شروع', moment(moment(rest.StartDate).format('YYYY-MM-DD'))._i),
            createData('تاریخ پایان', moment(moment(rest.EndDate).format('YYYY-MM-DD'))._i),
            createData('میزان پیشنهادی', '$' + NumberWithCommas(rest.SpecialOfferPrice)),
            createData('قیمت پیشنهادی', NumberWithCommas(rest.SpecialOfferUnitPrice) + ' تومان')
        ]
        const x = data.map((content, i) =>
            <TableRow key={i}>
                <TableCell sx={{ color: handleColor, borderColor: handleColor }}>{content.title}</TableCell>
                <TableCell sx={{ color: handleColor, borderColor: handleColor }}>{content.data}</TableCell>
            </TableRow>)
        return (x)
    }
    const renderDropDown = () => {
        const dropDownItems = exchangeList.map((content, i) => <MenuItem key={i} value={content.Name}>{content.Name}</MenuItem>)
        return (dropDownItems)
    }


    return (
        <>
            <Container>
                <Box align='center' sx={{ mb: 2, fontWeight: 'bold' }}>
                    قیمت خرید ویژه
                </Box>
                <Box align='center' sx={{ backgroundColor: '#1A9D2F1A', padding: '17px', borderRadius: '5px' }}>
                    جهت ثبت خرید ویژه جدید، می بایست ابتدا وضعیت
                    آخرین خرید ویژه خود را مشخص نمائید.
                </Box>
                <Grid container sx={{ mt: 3 }}>
                    <TableContainer sx={{ borderRadius: '6px', border: '1px solid ', borderColor: handleColor }}>
                        <Table aria-label='special price table'>
                            <TableHead className="table--head">
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ borderColor: handleColor }}>
                                        <Grid item xs={12} container direction='row' alignItems='center' justifyContent='space-between'>
                                            <Box sx={{ color: handleColor }}>
                                                فعال
                                            </Box>
                                            <Switch
                                                checked={handleActive}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange={handleActiveing}
                                            />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="table--body">
                                {
                                    getSpecialBuy === undefined || getSpecialBuy === null ? null : renderDatas(getSpecialBuy)
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container columnSpacing={2} sx={{ mt: 4 }}>
                        <Grid item xs={6}>
                            <Button
                                label='در ir guild فروختم'
                                sx={{ padding: '17px 0 !important' }}
                                type="submit"
                                variant="outlined"
                                onClick={() => {
                                    setStatus(1)
                                    setHandleDialog(true)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                label='خارج از ir guild فروختم'
                                sx={{ padding: '17px 0 !important' }}
                                type="submit"
                                variant="outlined"
                                onClick={() => {
                                    sendStatusandPrice("", 2)
                                    setSnackbar(true)
                                    setTimeout(() => {
                                        setStatus(2)
                                        navigate('/profile/exchange-offers')
                                    } , 3000)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                label='منقضی شد'
                                type="submit"
                                variant="outlined"
                                onClick={() => {
                                    sendStatusandPrice("", 4)
                                    setSnackbar(true)
                                    setTimeout(() => {
                                        setStatus(4)
                                        navigate('/profile/exchange-offers')
                                    } , 3000)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                label='منصرف شدم'
                                type="submit"
                                variant="outlined"
                                onClick={() => {
                                    sendStatusandPrice("", 3)
                                    setSnackbar(true)
                                    setTimeout(() => {
                                        setStatus(3)
                                        navigate('/profile/exchange-offers')
                                    } , 3000)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Note archived"
                >
                    <Alert severity="success">تعیین وضعیت با موفقیت انجام شد. </Alert>
                </Snackbar>
            </Container>
            <Dialog onClose={() => setHandleDialog(false)} open={handleDialog}>
                <Container>
                    <Grid bgcolor='#F6F6F6' border='1px solid #d3d3d3' borderRadius='15px' padding='24px' sx={{ mt: 4 }}>
                        <Box textAlign='center' sx={{ mb: 4 }}>
                            نام صرافی مورد نظر را انتخاب نمائید
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box>
                                <Grid item xs={12} sx={{ mb: 2, width: '100%' }}>
                                    <Controller
                                        name="SpecialHistoryDealerName"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <FormControl fullWidth>
                                                <InputLabel sx={{ backgroundColor: 'white' }} id="exchange--label">صرافی</InputLabel>
                                                <Select
                                                    sx={{ backgroundColor: 'white' }}
                                                    labelId='exchange--label'
                                                    value={value}
                                                    label="صرافی "
                                                    onChange={onChange}
                                                    error={error}
                                                    fullWidth
                                                >
                                                    {renderDropDown()}
                                                </Select>
                                            </FormControl>
                                        )}
                                        rules={{ required: true }}
                                    />
                                </Grid>
                                <Grid display='flex' justifyContent='center' sx={{ mt: 4 }}>
                                    <Button label='ثبت' type="submit" variant="contained" color="success" fullWidth={false} sx={{ borderRadius: '15px', width: '125px' }} className="btn" />
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </Container>
            </Dialog>
        </>
    )
}  
