export const MainDoamin = 'https://irguild.com'

export const LoginURL = MainDoamin + '/API/JwtAuth/mobile/login'
export const ExtendTokenURL = MainDoamin + '/API/JwtAuth/mobile/extendtoken'
export const SendEmailVerify = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SendEmailVerify'
export const VerifyEmailUrl = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/VerifyEmail'
export const RegisterUrl = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/Register'
export const GetAllRates = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetAllRates'
export const GetExchangesBuyPrice = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetExchangesBuyPrice'
export const GetExchangesSalePrice = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetExchangesSalePrice'
export const GetSpecialExchangesBuyPrice = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetExchangesSpecialBuyPrice'
export const GetSpecialExchangesSalePrice = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetExchangesSpecialSalePrice'
export const GetUserOffers = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetUserOffers'
export const GetMyOffer = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetMyOffer'
export const SubmitOffer = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SubmitOffer'
export const GetUserInfo = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetUserInfo'
export const GetLastSpecialBuy = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetLastSpecialBuy'
export const GetLastSpecialSale = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetLastSpecialSale'
export const GetExchangeInfo = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetExchangeInfo'
export const SetNewHistoryAndUpdateSpecialOffer = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SetNewHistoryAndUpdateSpecialOffer'
export const GetExchangeRolesUsers = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/GetExchangeRolesUsers'
export const SubmitBuyPrice = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SubmitBuyPrice'
export const SubmitSalePrice = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SubmitSalePrice'

export const SubmitSpecialBuy = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SubmitSpecialBuy'
export const SubmitSpecialSale = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SubmitSpecialSale'

export const SendSpecialBuyNotificationToExchangeUsers = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SendSpecialBuyNotificationToExchangeUsers'
export const SendSpecialSaleNotificationToExchangeUsers = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/SendSpecialSaleNotificationToExchangeUsers'
export const EditSpecialBuy = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/EditSpecialBuy'
export const EditSpecialSale = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/EditSpecialPrice'
export const CreateChat = MainDoamin + '/API/WB_SimpleChat/Chat/CreateChat'
export const ApproveOffer = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/ApproveOffer'
export const GetNotification = MainDoamin + '/API/WB_SimpleChat/Notification/GetNotificationByUser'
export const SeenNotification = MainDoamin + '/API/WB_SimpleChat/Notification/SeenNotification'
export const ForgetPassword = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/ForgetPassword'
export const DeleteOffer = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/DeleteOffer'
export const AllOffersForExchange = MainDoamin + '/API/WB_Exchange_Online/ExchangeService/AllOffersForExchange'


