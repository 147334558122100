
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { PostService } from '../services/Fetch'
import { RegisterUrl, LoginURL } from '../config'
import Container from '../components/Container'
import Cookies from 'js-cookie'
import { Grid } from "@mui/material";
import SetPageTitle from "../utils/SetPageTitle";
import { useSelector, useDispatch } from 'react-redux'
import { setLoginInfo } from '../redux/reducer'



export default function SubmitNewInformation(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const UserIdForRegister = useSelector(state => state.state.userIdForRegister)

    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            UserId: UserIdForRegister
        }
    });

    const passwordInput = watch('Password')

    const onSubmit = async (data) => {
        const { confirmation, ...rest } = data
        let response = await PostService(RegisterUrl, rest, false)
        if (response?.StatusCode === 200) {
            const compiledData = {
                u: Cookies.get('Email'),
                p: data.Password
            }
            let response = await PostService(LoginURL, compiledData, false)
            if (response) {
                dispatch(setLoginInfo(response))
                navigate(`/`)
                Cookies.set('loginData', JSON.stringify(response), { expires: 14 }) // 1/24 = 1 hour
            } else {
                return
            }
        } else {

        }
    }; 

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])


    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: 'calc(100vh - 235px)' }}>
                <Grid container direction="column" justifyContent="space-between" sx={{ height: '100%', flexWrap: 'nowrap' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                                name="FirstName"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={'نام'}
                                        fullWidth
                                        variant="outlined"
                                        size="large"
                                        type="text"
                                        className="TextField"
                                        value={value}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                rules={{ required: 'وارد کردن این فیلد اجباری است ' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                                name="LastName"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={'نام خانوادگی'}
                                        fullWidth
                                        variant="outlined"
                                        size="large"
                                        type="text"
                                        className="TextField"
                                        value={value}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                rules={{ required: 'وارد کردن این فیلد اجباری است ' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                                name="Password"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={'کلمه عبور'}
                                        fullWidth
                                        variant="outlined"
                                        size="large"
                                        type="password"
                                        className="TextField"
                                        value={value}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={({ target: { value } }) => {
                                            onChange(value);
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                rules={{
                                    required: 'وارد کردن این فیلد اجباری است ',
                                    validate: value => value.length > 6 || "پسورد باید شامل حداقل 7 کاراکتر باشد"
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Controller
                                name="confirmation"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={'تکرار کلمه عبور'}
                                        fullWidth
                                        variant="outlined"
                                        size="large"
                                        type="password"
                                        className="TextField"
                                        value={value}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={({ target: { value } }) => {
                                            onChange(value);
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                                rules={{
                                    required: 'وارد کردن این فیلد اجباری است ',
                                    validate: value => value === passwordInput || "تکرار کلمه عبور با کلمه عبور یکسان نیست"
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'flex-end', mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary" fullWidth className="btn">
                            ثبت اطلاعات
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container >
    )
}  