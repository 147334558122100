import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'


export const ExchangeRoute = () => {
  const UserRoles = useSelector(state => state.state.roles)
  return (
    <>
      {UserRoles.Exchange !== null ?
        UserRoles.Exchange ? <Outlet /> : <Navigate to="/" />
        :
        null
      }

    </>
  );
}
export const UserRoute = () => {
  const UserRoles = useSelector(state => state.state.roles)
  return !UserRoles.Limited && !UserRoles.Exchange ? <Outlet /> : <Navigate to="/" />;
}

// export default ExchangeRoute