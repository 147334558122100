import React, { useEffect, useState } from "react";
import { Box, Dialog, Button, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { Outlet } from 'react-router-dom'
import Swal from 'sweetalert2'
import PwaVideo from '../irguildpwa.mp4'


const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;


  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}


const PWAInstaller = () => {


  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isIos, setIsIos] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = evt => {
    // evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  if (!supportsPWA) {
    // return null;
  }

  useEffect(() => {

    let PressedNo = isNaN(parseInt(localStorage.getItem("PressedNo"))) ? 0 : parseInt(localStorage.getItem("PressedNo"))
    if (localStorage.getItem("Help_Suggestion") === null)
      if (!window.matchMedia('(display-mode: standalone)').matches && PressedNo !== 3)
        Swal.fire({
          text: 'آیا مایل به نصب برنامه هستید؟',
          showCloseButton: true,
          showCancelButton: true,
          // cancelButtonColor: '#d33',
          cancelButtonText: 'خیر',
          confirmButtonText: 'بله',
          confirmButtonColor: '#1A9D2F',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.setItem("Help_Suggestion", "Yes");
            if (getMobileOperatingSystem() !== "iOS")
              onClick();
            else
              openIosInstallHelpter();
              handleOpen()
          } else {
            localStorage.setItem("PressedNo", PressedNo + 1);
          }
        })

  }, [promptInstall]);


  const openIosInstallHelpter = () => {
    setIsIos(true)
    // Swal.fire({
    //   icon: 'info',
    //   html:
    //     `<div class="iosHelp">
    //     <video height="240" controls>
    //       <source src="https://f.webcade.ir/irguildpwa.mp4" type="video/mp4">
    //       Your browser does not support the video tag.
    //     </video>

    //       <ul class="helping text-light">
    //       <li><p>1- برنامه حتما در مرورگر سافاری باز شود.</p></li>
    //       <li><p>2- درنوار پایین روی دکمه <strong>Share</strong> تپ کنید.</p></li>
    //       <li><p>3- در منوی بازشده، در قسمت پایین،
    //           <br />
    //           گزینه <strong>Add to Home Screen</strong> را انتخاب کنید.</p></li>
    //         <li><p>4- در مرحله بعد در قسمت بالا روی <strong>Add</strong> تپ کنید.</p></li>
    //       </ul>
    //     </div>`
    //   ,
    //   showCloseButton: true,
    //   focusConfirm: false,
    //   confirmButtonText:
    //     'بازگشت',
    // })
  }


  const handleClose = () => {
    localStorage.setItem('visitedModal', JSON.stringify(true))
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {
        isIos ?
        <div>
          <Dialog
              open={open}
              onClose={handleClose}
              sx={{padding: '1rem'}}
          >
            <DialogActions>
                  <Button onClick={handleClose} autoFocus sx={{color: '#ccc'}}>
                      <ClearIcon />
                  </Button>
            </DialogActions>
            <DialogContent>
                <video controls style={{height :'240px', width: '100%', marginBottom: '3rem'}}>
                    <source src={PwaVideo} type="video/mp4" />
                </video>
                <DialogContentText sx={{fontWeight: '600'}}>
                  <ul style={{listStyle: 'none', marginRight: '-1.5rem'}} className="helping text-light">
                   <li>1- برنامه حتما در مرورگر سافاری باز شود.</li><br />
                   <li>2- درنوار پایین روی دکمه <strong>Share</strong> تپ کنید.</li><br />
                   <li>3- در منوی بازشده، در قسمت پایین،
                       <br />
                       گزینه <strong>Add to Home Screen</strong> را انتخاب کنید.</li><br />
                     <li>4- در مرحله بعد در قسمت بالا روی <strong>Add</strong> تپ کنید.</li>
                  </ul>
                </DialogContentText>
                <Box display='flex' sx={{mt: 3}} justifyContent='center' >
                    <Button onClick={handleClose} variant="contained">بازگشت</Button>
                </Box>
            </DialogContent>
          </Dialog>
        </div>
        : 
        null
      }
      <Outlet />
    </>
  );
}
export default PWAInstaller