import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Box } from "@mui/material";

export default function ExchangeBox(props) {
  return (
    <Box className='exchange--wrap' sx={{ backgroundColor: '#F7F7F7', borderRadius: '30px', border: '1px solid #E1E1E1', }}>
      <Box className='' sx={{ borderBottom: '1px solid #DCDCDC', py: 2, px: 2 , mb:2}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CurrencyExchangeIcon sx={{ color: '#fff', backgroundColor: props.backgroundColor, padding: '4px', borderRadius: '100%' }} />
          <span style={{ color: '#000', fontSize: '18px', fontWeight: '700', marginRight: '5px' }}>
            {props.title}
          </span>
        </Box>
      </Box>
      <Box className='exchange--items user--offer--items' sx={{pb:2}}>
        {props.children}
      </Box>
    </Box>
  )

}



