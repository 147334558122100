import { ListItem, ListItemText, ListItemIcon, Divider, Box, Dialog, Grid, Container, List } from '@mui/material';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import NumberWithCommas from './NumberWithCommas'
import React from 'react';
import moment from 'moment/moment';
import { PostService } from '../services/Fetch';
import { DeleteOffer } from '../config';
import { Snackbar, Alert } from '@mui/material'; 

export default function UserOfferList(props) {
	const [open, setOpen] = React.useState(false)
	const [snackbar, setSnackbar] = React.useState(false)

	const handleDelete = async () => {
		let response = await PostService(DeleteOffer, { Id: props.data.Id })
		if(response?.StatusCode === 200) {
			setSnackbar(true)
			window.location.reload(false);
		}
	}
	const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
			setSnackbar(false);
        }
    };
	const seprateToman = NumberWithCommas(props.data.Amount)
	const seprateUnit = NumberWithCommas(props.data.UnitPrice)

	return(
		<>
			<ListItem sx={{padding: '1rem 0'}}>
				<ListItemText sx={{color: '#426BA2', paddingLeft: '1.5rem'}} primary={"$" + seprateUnit} />
				<ListItemText sx={{color: '#426BA2'}} primary= {seprateToman + " تومان"} />
				<ListItemIcon>
					<HighlightOffIcon onClick={handleDelete} sx={{cursor: 'pointer' , color : '#FF834F', fontSize: '2rem' , marginLeft: '0.25rem'}} />
				</ListItemIcon>
				<ListItemIcon>
					<InfoSharpIcon onClick={() => setOpen(state => !state)} sx={{cursor: 'pointer' , color : '#838383', fontSize: '2rem'}} />
				</ListItemIcon>
			</ListItem>
			<Divider />
			<Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: { bgcolor: "transparent", borderRadius: '30px' }}} fullWidth>
				<Container sx={{backgroundColor: '#F6F6F6', borderRadius: '30px',}}>
					<Grid xs={12}>
						<Box fullWidth sx={{display: 'flex', padding: '1rem 0', borderBottom : '1px solid #DCDCDC'}} justifyContent='start' alignItems='center'>
							<CurrencyExchangeIcon sx={{ marginRight:'0.5rem', color: '#fff', backgroundColor: '#00BC1E' , padding: '4px', borderRadius: '100%' }} />
							<Box sx={{fontWeight: 'bold'}}>
								خرید
							</Box>
						</Box>
						<Box sx={{width: '100%'}}>
							<List sx={{width: '100%'}}>
								<ListItem sx={{width: '100%', padding: '0.5rem'}}>
									<ListItemText primary='تاریخ ثبت' />
									<ListItemText sx={{textAlign: 'right'}} primary={moment(moment(props.data.StartDate).format(' HH:mm - YYYY/MM/DD '))._i } />
								</ListItem>
								<ListItem sx={{width: '100%', padding: '0.5rem'}}>
									<ListItemText primary='تاریخ شروع' />
									<ListItemText sx={{textAlign: 'right'}} primary={moment(moment(props.data.StartDate).format(' HH:mm - YYYY/MM/DD '))._i} />
								</ListItem>
								<ListItem sx={{width: '100%', padding: '0.5rem'}}>
									<ListItemText primary='تاریخ پایان' />
									<ListItemText sx={{textAlign: 'right'}} primary={moment(moment(props.data.EndDate).format(' HH:mm - YYYY/MM/DD '))._i} />
								</ListItem>
								<ListItem sx={{width: '100%', padding: '0.5rem'}}>
									<ListItemText primary='حجم' />
									<ListItemText sx={{textAlign: 'right'}} primary={"$" + NumberWithCommas(props.data.UnitPrice)} />
								</ListItem>
								<ListItem sx={{width: '100%', padding: '0.5rem'}}>
									<ListItemText primary='قیمت پیشنهادی' />
									<ListItemText sx={{textAlign: 'right'}} primary={NumberWithCommas(props.data.Amount) + " تومان"} />
								</ListItem>
							</List>
						</Box>
					</Grid>
				</Container>
			</Dialog>
			<Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Note archived"
                >
                    <Alert severity="success">حذف پیشنهاد با موفقیت انجام شد</Alert>
            </Snackbar>
		</>
	)
}


