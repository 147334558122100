
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { PostService } from '../services/Fetch'
import { SendEmailVerify } from '../config'
import Container from '../components/Container'
import Cookies from 'js-cookie'
import { Grid } from "@mui/material";
import SetPageTitle from "../utils/SetPageTitle";
import EmailControl from "../components/controles/EmailControl"
import { Button } from "../components/inputs/Button"
import { setSnackBarStatus } from '../redux/reducer'
import { useDispatch } from 'react-redux'


export default function Register(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { handleSubmit, control } = useForm({
        defaultValues: {
        }
    }); 
    const onSubmit = async (data) => {
        const compiledData = {
            Email: data.Email,
            EmailTemplateHTML: "[0]",
            Type: "Register"
        }
        let response = await PostService(SendEmailVerify, compiledData, false)
        if (response?.StatusCode === 200) {
            Cookies.set('EncryptCode', response.Data, { expires: 1 / 360 }) // 1/360 = 2 Min
            Cookies.set('Email', data.Email, { expires: 1 / 24 }) // 1/24 = 1 H
            Cookies.set('ExpireDate', Date(), { expires: 1 / 24 }) // 1/360 = 2 Min
            dispatch(setSnackBarStatus({ state: true, type: 'success', msg: 'کد 4 رقمی به ایمیل شما ارسال شد' }))
            navigate(`/verify-email`)
        } else {
            dispatch(setSnackBarStatus({ state: true, type: 'error', msg: response.Data }))
        }
    };

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])


    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: 'calc(100vh - 235px)' }}>
                <Grid container spacing={2} direction="column" justifyContent="space-between" sx={{ height: '100%', flexWrap: 'nowrap' }}>
                    <Grid item xs={12} sm={12} md={12} >
                        <EmailControl name='Email' control={control} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Button label='مرحله بعد' type='submit' />
                    </Grid>
                </Grid>
            </form>
        </Container >
    )
}   