
import React, { useEffect } from "react";
import Container from '../components/Container'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfSharpIcon from '@mui/icons-material/StarHalfSharp';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux'
import { setLoginInfo, setChatNotification, setUserInfo } from '../redux/reducer'
import { useNavigate, NavLink } from 'react-router-dom';
import Cookies from 'js-cookie'
import SetPageTitle from "../utils/SetPageTitle";
import { useSelector } from 'react-redux'
import Box from "@mui/material/Box";
import { GetService, PostService } from "../services/Fetch";
import { GetLastSpecialBuy, GetLastSpecialSale, GetExchangeInfo } from "../config";

const liStyle = {
    padding: '20px 0 '
},
    iconStyle = {
        borderRadius: '12px',
        padding: '4px',
        color: '#fff'
    },
    arrowStyle = {
        color: '#838383'
    }

export default function Profile(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const UserInfo = useSelector(state => state.state.userInfo)
    const UserRoles = useSelector(state => state.state.roles)

    const [getSpecialBuy, setGetSpecialBuy] = React.useState();
    const [getSpecialSale, setGetSpecialSale] = React.useState();



    const FetchSpecialBuy = async () => {
        let responseId = await GetService(GetExchangeInfo)
        let temp2 = responseId.data
        if (temp2?.Data) {
            let response = await PostService(GetLastSpecialBuy, { ExchangeId: temp2.Data.Id })
            if (response?.Data) {
                setGetSpecialBuy(response.Data)
            }
        }
    }
    const FetchSpecialSale = async (rest) => {
        let responseId = await GetService(GetExchangeInfo)
        let temp2 = responseId.data
        if (temp2?.Data) {
            let response = await PostService(GetLastSpecialSale, { ExchangeId: temp2.Data.Id })
            if (response?.Data) {
                setGetSpecialSale(response.Data)
            }
        }
    }

    const LogOut = () => {
        Cookies.remove('loginData')
        Cookies.remove('isLogin')
        dispatch(setLoginInfo(null))
        dispatch(setUserInfo(null))
        dispatch(setChatNotification(0))
        navigate(`/`)
    }
    useEffect(() => {
        if (UserRoles.Exchange) {
            FetchSpecialBuy()
            FetchSpecialSale()
        }
    }, [])
    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    return ( 
        <>
            <Box sx={{ py: 6, textAlign: 'center', boxShadow: '0px 10px 15px rgb(211 211 211 / 27%)' }}>
                <Box sx={{ fontWeight: '600' }}>
                    {UserInfo?.DisplayName} <br />
                </Box>
                <Box sx={{ fontSize: '12px' }}>
                    {UserInfo?.Email}
                </Box>
            </Box>
            <Container>
                <div className="Profile">
                    <List>
                        {UserRoles.Exchange ?
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton style={liStyle} state={{ title: 'قیمت خرید' }} component={NavLink} to="/profile/buy-price">
                                        <ListItemIcon>
                                            <CurrencyExchangeIcon sx={{ backgroundColor: '#00BC1E' }} style={iconStyle} />
                                        </ListItemIcon>
                                        <ListItemText primary="قیمت خرید" />
                                        <KeyboardArrowLeftIcon style={arrowStyle} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton style={liStyle} state={{ title: 'قیمت فروش' }} component={NavLink} to="/profile/sale-price">
                                        <ListItemIcon>
                                            <CurrencyExchangeIcon sx={{ backgroundColor: '#DB0B0B' }} style={iconStyle} />
                                        </ListItemIcon>
                                        <ListItemText primary="قیمت فروش" />
                                        <KeyboardArrowLeftIcon style={arrowStyle} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </>
                            :
                            null
                        }

                        {UserRoles.Exchange || UserRoles.Limited ?
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton style={liStyle} state={{ title: 'خرید ویژه' }} component={NavLink} to={getSpecialBuy?.Status !== 0 ? '/profile/submit-special-buy' : '/profile/special-buy-price'}>
                                        <ListItemIcon>
                                            <StarBorderIcon sx={{ backgroundColor: '#003275' }} style={iconStyle} />
                                        </ListItemIcon>
                                        <ListItemText primary="خرید ویژه" />
                                        <KeyboardArrowLeftIcon style={arrowStyle} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton style={liStyle} state={{ title: 'فروش ویژه' }} component={NavLink} to={getSpecialSale?.Status !== 0 ? '/profile/submit-special-sale' : '/profile/special-sale-price'}>
                                        <ListItemIcon>
                                            <StarHalfSharpIcon sx={{ backgroundColor: '#003275' }} style={iconStyle} />
                                        </ListItemIcon>
                                        <ListItemText primary="فروش ویژه" />
                                        <KeyboardArrowLeftIcon style={arrowStyle} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </>
                            :
                            null
                        }

                        {UserRoles.Exchange === false && UserRoles.Limited === false ?
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton style={liStyle} state={{ title: 'پیشنهادات من' }} component={NavLink} to="/profile/my-offers">
                                        <ListItemIcon>
                                            <FormatListNumberedIcon sx={{ backgroundColor: '#FFB94F' }} style={iconStyle} />
                                        </ListItemIcon>
                                        <ListItemText primary="پیشنهادات من" />
                                        <KeyboardArrowLeftIcon style={arrowStyle} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </>
                            : null}

                        <ListItem disablePadding>
                            <ListItemButton onClick={LogOut} style={liStyle}>
                                <ListItemIcon>
                                    <LogoutIcon sx={{ backgroundColor: '#838383' }} style={iconStyle} />
                                </ListItemIcon>
                                <ListItemText primary="خروج" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
            </Container>
        </>
    )
}  