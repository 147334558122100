import { useSelector , useDispatch} from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {
  const loadingCount = useSelector(state => state.state.loadingCount)
  return (
    <div className={"loading--wrap " + (loadingCount !== 0 ? 'show--loading' : '')}>
      <CircularProgress />
    </div>
  )
}



