import React from "react";
import { Controller } from "react-hook-form";
import TextInput from "../inputs/TextInput"
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const EmailControl = ({
  name,
  label = 'کلمه عبور',
  defaultValue = '',
  control,

}) => {

  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextInput
          label='کلمه عبور'
          type={showPassword ? 'text' : 'password'}
          onChange={onChange}
          value={value}
          error={error}
          autoComplete="off"
          className="align--left"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      rules={{ required: 'وارد کردن کلمه عبور اجباری است ' }}
    />

  )
}

export default EmailControl