
import React, { useEffect } from "react";
import Container from '../components/Container'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { SendSpecialBuyNotificationToExchangeUsers } from "../config";
import { Controller, useForm } from "react-hook-form";
import { Grid, InputAdornment, TextField } from "@mui/material";
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { SubmitSpecialBuy, GetExchangeInfo } from "../config"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { PostService, GetService } from "../services/Fetch";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SetPageTitle from "../utils/SetPageTitle";
import FormHelperText from '@mui/material/FormHelperText';
import NumberWithCommas from '../components/NumberWithCommas'



export default function SubmitNewSpecialBuy(props) {
    const navigate = useNavigate()
    const { handleSubmit, control, watch } = useForm({});
    const [snackbar, setSnackbar] = React.useState(false);
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(false);
    };

    const onSubmit = async (data) => {
        let resposneId = await GetService(GetExchangeInfo)
        console.log(data)

        const postBody = {
            ExchangeId: resposneId.data.Data.Id,
            ExchangeName: resposneId.data.Data.Name,
            SpecialOfferStartDate: moment(moment(data.FromDate).format('YYYY-MM-DD') + "  " + data.FromHour.format("HH:mm:ss"))._i,
            SpecialOfferEndDate: moment(moment(data.ToDate).format('YYYY-MM-DD') + "  " + data.ToHour.format("HH:mm:ss"))._i,
            SpecialOfferTypeText: "خرید ویژه‌ی جدیدی (انتقال به ایران)",
            SpecialUnitOfferPrice: data.unitPrice,
            SpecialOfferPrice: data.amount,
            ExchangeMail: '',
            ExchangeTelegram: '',
            ExchangeWebsite: '',
            ExchangeWhatsApp: '',
            SpecialOfferDescription: ''
        }
        
        let resposne = await PostService(SubmitSpecialBuy, postBody)
        let notifResponse = await PostService(SendSpecialBuyNotificationToExchangeUsers, postBody)
        if(resposne && notifResponse) {
            setSnackbar(true);
        }
        setTimeout(() => { navigate('/profile/exchange-offers') }, 3000)
    }
    const cancleSubmit = (e) => {
        e.preventDefault()
        navigate('/profile')
    }

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    const inputPrice = watch('unitPrice')
    const inputAmount = watch('amount')

    Date.prototype.getNextWeekDay = function(d) {

        let next = this;
        next.setDate(this.getDate() - this.getDay() + 7 + d);
        return next;
      
    }

    let time = new Date()

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
                <Box sx={{ backgroundColor: '#F5F5F5', textAlign: 'center', padding: '17px', mb: 3, fontWeight: '600' }}>
                    چه مقدار قصد ثبت خرید ویژه را دارید؟
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Controller
                            name="amount"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={'میزان پیشنهاد'}
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    type="number"
                                    autoComplete="off"
                                    className="TextField align--left"
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="start">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                $
                                            </InputAdornment>
                                        ,
                                    }}
                                    value={value}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{ required: 'وارد کردن این قسمت الزامی است ' }}
                        />
                        {inputAmount &&
                            <FormHelperText sx={{
                                direction: 'rtl',
                                textAlign: 'right',
                                paddingRight: '80px'
                            }}
                            >
                                {NumberWithCommas(inputAmount)}
                            </FormHelperText>
                        }

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} >
                        <Controller
                            name="unitPrice"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={'قیمت پیشنهادی شما (واحد)'}
                                    fullWidth
                                    variant="outlined"
                                    size="large"
                                    type="number"
                                    step="any"
                                    autoComplete="off"
                                    className="TextField align--left"
                                    InputProps={{
                                        inputMode: 'numeric',
                                        pattern: "[0-9]*",
                                        endAdornment:
                                            <InputAdornment position="start">تومان</InputAdornment>
                                        ,
                                    }}

                                    value={value}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            rules={{ required: 'وارد کردن این قسمت الزامی است ' }}
                        />
                        {inputPrice &&
                            <FormHelperText sx={{
                                direction: 'rtl',
                                textAlign: 'right',
                                paddingRight: '80px'
                            }}
                            >
                                {NumberWithCommas(inputPrice)}
                            </FormHelperText>
                        }

                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ mb: 1 }}>از تاریخ</Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                            <Controller
                                name="FromDate"
                                control={control}
                                defaultValue={new Date()}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <MobileDatePicker
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) =>                  
                                            <TextField fullWidth size="large" {...params}
                                                placeholder="انتخاب کنید"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        }
                                    />
                                )}
                                rules={{
                                    required: 'وارد کردن این قسمت الزامی است'
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ mb: 1 }}>تا تاریخ</Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Controller
                                name="ToDate"
                                control={control}
                                defaultValue={new Date().getNextWeekDay(6)}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <MobileDatePicker
                                        value={value}
                                        onChange={onChange}
                                        renderInput={(params) =>
                                            <TextField fullWidth size="large" {...params}
                                                placeholder="انتخاب کنید"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        }
                                    />
                                )}
                                rules={{
                                    required: 'وارد کردن این قسمت الزامی است'
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ mb: 1 }}>از ساعت</Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="FromHour"
                                control={control}
                                defaultValue={dayjs()}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <MobileTimePicker
                                        value={value}

                                        onChange={(newValue) => {
                                            onChange(dayjs(newValue))
                                        }}
                                        renderInput={(params) =>
                                            <TextField fullWidth size="large" {...params}
                                                placeholder="انتخاب کنید"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        }
                                    />
                                )}
                                rules={{
                                    required: 'وارد کردن این قسمت الزامی است'
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ mb: 1 }}>تا ساعت</Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                                name="ToHour"
                                control={control}
                                defaultValue={dayjs().hour(24).minute(0)}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <MobileTimePicker
                                        value={value}
                                        onChange={(newValue) => {
                                            onChange(dayjs(newValue))
                                        }}
                                        renderInput={(params) =>
                                            <TextField fullWidth size="large" {...params}
                                                placeholder="انتخاب کنید"
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        }
                                    />
                                )}
                                rules={{
                                    required: 'وارد کردن این قسمت الزامی است'
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} >
                        <Button type="submit" variant="contained" color="primary" fullWidth className="btn">
                            ثبت
                        </Button>
                        <Button type="submit" onClick={cancleSubmit} variant="contained" color="cancel" fullWidth className="btn">
                            انصراف
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Note archived"
                >
                    <Alert severity="success">ثبت پیشنهاد با موفقیت انجام شد.</Alert>
                </Snackbar>

            </Container>
        </form >
    )
}  