import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

const ProtectedRoute = () => {
  var auth = null; // determine if authorized, from context or however you're doing it
  const LoginData = Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null;
  auth = useSelector(state => state.state.loginInfo);

  const LoginInfo = useSelector(state => state.state.loginInfo)
  const authLoading = useSelector(state => state.state.authLoading)


  if (!auth && LoginData) {
    auth = LoginData
  }
  if (!authLoading) {
    return null
  }

  return LoginInfo && authLoading ? <Outlet /> : <Navigate to="/login" />;
}
export default ProtectedRoute