
import React, { useEffect } from "react";
// import logo from '/images/logo.svg';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { PostService, GetService } from '../services/Fetch'
import {
    GetAllRates,
    GetExchangesBuyPrice,
    GetExchangesSalePrice,
    CreateChat,
} from '../config'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ExchangeBox from '../components/ExchangeBox'
import ExchangeContent from '../components/ExchangeContent'
import { useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton';


const CurrentDate = () => {
    const today = new Date();
    function formatDate(date, format) {
        return (
            format.replace('mm', date.getMonth() + 1)
                .replace('yy', date.getFullYear())
                .replace('dd', date.getDate())
        )
    }

    const now = formatDate(today, 'yy/mm/dd');
    return now
}

const ToggleButtonStyle = {
    border: 'none',
    color: "#fff",
    borderRadius: '10px',
    padding: '5px 8px',
    minWidth: '120px'
}

const ExchangeSkeletonTemplate = () => {
    return (
        <Skeleton animation="wave" variant="rounded" height={58} sx={{ width: '100%', marginBottom: '10px' }} />
    )
}




export default function Home(props) {
    const navigate = useNavigate();
    const LoginInfo = useSelector(state => state.state.loginInfo)
    const [exChange, setExChange] = React.useState(3);
    const [allRates, setAllRates] = React.useState()
    const [exchangesBuyPrice, setExchangesBuyPrice] = React.useState()
    const [exchangesSalePrice, setExchangesSalePrice] = React.useState()
    const handleExChange = (event, newExChange) => {
        if (newExChange !== null) {
            setExChange(newExChange)
            FetchGetExchangesBuyPrice(newExChange)
            FetchGetExchangesSalePrice(newExChange)
        }
    };

    const FetchGetAllRates = async () => {
        let response = await GetService(GetAllRates, false)
        setAllRates(response.data.Data);
        let temp = []
        temp.sort()
        for (let i = 0; i < response.data.Data.length; i++) {
            temp.push(response.data.Data[i].DisplayOrder)
            if (response.data.Data[i].DisplayOrder === temp[0]) {
                setExChange(response.data.Data[i].Id)
            }
        }

    }
    const FetchGetExchangesBuyPrice = async (exChange) => {
        let response = await PostService(GetExchangesBuyPrice, { RateId: exChange }, false)
        setExchangesBuyPrice(response.Data);
    }

    const FetchGetExchangesSalePrice = async (exChange) => {
        let response = await PostService(GetExchangesSalePrice, { RateId: exChange }, false)
        setExchangesSalePrice(response.Data);
    }


    useEffect(() => {
        FetchGetAllRates()
        FetchGetExchangesBuyPrice(exChange)
        FetchGetExchangesSalePrice(exChange)
    }, [])

    const FetchChat = async (UserId, DisplayName) => {
        if (!LoginInfo)
            navigate('/login')
        else {
            let response = await PostService(CreateChat, { To: UserId })
            navigate("/messages/detail", {
                state: {
                    ChatId: response.Data.Id,
                    targetUserId: UserId,
                    DisplayName: DisplayName
                }
            });
        }
    }


    useEffect(() => {
        document.title = props.title
    }, [props.title])

    useEffect(() => {
        document.body.classList.add('home');

        return () => {
            document.body.classList.remove('home');
        };
    });

    return (
        <div className="Home">
            <Box className="top--section" sx={{ height: '280px' }}>
                <div className="logo--wrap">
                    <img src='/images/logo.svg' className="logo" alt="logo" />
                </div>
                <Box className="date--wrap" sx={{ color: '#fff', px: 3, height: '25px' }}>
                    <span style={{ fontWeight: '600' }}>
                        فیلتر بر اساس میزان تراکنش
                    </span>
                    <span>
                        <CurrentDate />
                    </span>
                </Box>
                <Box>
                    {allRates ?
                        <ToggleButtonGroup
                            value={exChange}
                            exclusive
                            onChange={handleExChange}
                            aria-label="text alignment"
                            className="sort--price"
                            sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', my: 2, px: 1 }}
                        >

                            {allRates.map(function (item, index) {
                                return (
                                    <ToggleButton
                                        key={index}
                                        value={item.Id}
                                        style={ToggleButtonStyle}
                                    >
                                        {item.Title}
                                    </ToggleButton>
                                )
                            })}
                        </ToggleButtonGroup>
                        :
                        <Box
                            sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}
                        >
                            <Skeleton animation="wave" variant="rounded" width={120} height={34} />
                            <Skeleton animation="wave" variant="rounded" width={120} height={34} />
                            <Skeleton animation="wave" variant="rounded" width={120} height={34} />
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '28px', color: '#fff', pb: 5, fontWeight: '600' }}>
                    {allRates ?
                        allRates.map(function (item, index) {
                            if (item.Id === exChange)
                                return item.Title
                        })
                        :
                        <Skeleton animation="wave" variant="rounded" width={205} height={43} />
                    }
                </Box>
            </Box>

            <Box sx={{ position: 'relative', top: '-30px', fontWeight: '600' }}>
                <ExchangeBox
                    title='خرید'
                    backgroundColor='#00BC1E'
                    sx={{ fontWeight: '600' }}
                >
                    {exchangesBuyPrice ?
                        exchangesBuyPrice.map(function (item, index) {
                            return (
                                <ExchangeContent key={index} data={item} method="buy" FetchChat={FetchChat} />
                            )
                        })
                        :
                        <>
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                        </>
                    }

                </ExchangeBox>
            </Box>
            <Box sx={{ mt: 1, fontWeight: '600' }}>
                <ExchangeBox
                    title='فروش'
                    backgroundColor='#DB0B0B'
                >
                    {exchangesSalePrice ?
                        exchangesSalePrice.map(function (item, index) {
                            return (
                                <ExchangeContent key={index} data={item} method="sale" FetchChat={FetchChat} />
                            )
                        })
                        :
                        <>
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                        </>
                    }

                </ExchangeBox>
            </Box>



        </div >

    )
}  