
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import SetPageTitle from "../utils/SetPageTitle";
import ChatWindow from '../components/ChatWindow';
import ChatInput from '../components/ChatInput';
import { useLocation } from 'react-router-dom';
import { Box } from "@mui/material";
import { setMsgApprove, setSeenChatList } from '../redux/reducer'


export default function Chat(props) {
    const dispatch = useDispatch()
    const LoginInfo = useSelector(state => state.state.loginInfo),
        MsgApprove = useSelector(state => state.state.msgApprove),
        location = useLocation(),
        ChatId = location.state.ChatId,
        targetUserId = location.state.targetUserId,
        DisplayName = location.state.DisplayName,
        latestChat = useRef(null),
        [chat, setChat] = useState([]);

    useEffect(() => {
        SetPageTitle(DisplayName)
    }, [DisplayName])

 
    latestChat.current = chat
    useEffect(() => {
        let flag = true
        if (props?.connection?._connectionState === "Connected" && LoginInfo && ChatId) {
            props.connection.send('GetMessagesChat', ChatId, LoginInfo.accessToken);

            props.connection.on('MessagesChat', res => {

                setChat(res.data);
                seenMessageOnLoad(res.data)
            });

            props.connection.on('RefreshMessagesChat', res => {
                if (flag) {
                    const updatedChat = [...latestChat.current];
                    updatedChat.push(JSON.parse(res).Data);
                    setChat(updatedChat);
                    seenMessage([JSON.parse(res).Data])
                }
            });

            props.connection.on('SeenMessage', res => {
                const updatedChat = [...latestChat.current].reverse();
                updatedChat.find(function (item, index) {
                    if (res.includes(item.Id)) {
                        updatedChat[index].IsSeen = true
                    }
                })
                setChat(updatedChat.reverse())
            });


            const seenMessageOnLoad = (data) => {
                let list = []
                let Uid;
                data.map(function (item) {
                    if (item.UserId !== LoginInfo.userId && !item.IsSeen) {
                        list.push(item.Id)
                        Uid = item.UserId
                    }
                })
                if (list.length) {
                    props.connection.send("SeenMessage", list, ChatId, Uid, LoginInfo.accessToken);
                    dispatch(setSeenChatList(ChatId))
                }
            }
            const seenMessage = (data) => {
                props.connection.send("SeenMessage", [data[0].Id], ChatId, data[0].UserId, LoginInfo.accessToken);
            }

            return (() => {
                flag = false
            })

        }
    }, [props.connection]);

    useEffect(() => {
        document.body.classList.add('message-detail');

        return () => {
            document.body.classList.remove('message-detail');
        };
    });

    useEffect(() => {
        if (MsgApprove.exist) {
            sendMessage(`
            پیشنهاد ${MsgApprove.type} 
            به میزان (${MsgApprove.amount} $)
            قیمت واحد (${MsgApprove.unitPrice}) تومان
            تایید شد.
            `)
            dispatch(setMsgApprove({
                exist: false,
                type: '',
                amount: '',
                unitPrice: '',
            }))
        }
    }, []);

    const sendMessage = async (message) => {
        if (props.connection._connectionStarted) {
            try {
                await props.connection.send('SendMessage', {
                    AccessToken: LoginInfo.accessToken,
                    UserId: LoginInfo.userId,
                    To: targetUserId,
                    ChatId: ChatId,
                    Text: message,
                });
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

    return (
        <Box className="chat" sx={{
            backgroundColor: '#ddd',
            minHeight: 'calc(100vh - 136px)',
            height: '100%',
            paddingBottom: '60px',
            px: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        }}>
            <ChatInput sendMessage={sendMessage} />
            {!!chat && <ChatWindow chat={chat} />}

        </Box>
    );
}