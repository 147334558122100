
import React, { useEffect } from "react";
import Container from '../components/Container'
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import SetPageTitle from "../utils/SetPageTitle";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { List } from "@mui/material";
import {
    GetMyOffer,
} from '../config'
import { GetService } from '../services/Fetch'
import UserOfferList from "../components/UserOfferList";


export default function MyOffers(props) {
    const [buyList, setBuyList] = React.useState();
    const [saleList, setSaleList] = React.useState();
    const [refreshCount, setRefreshCount] = React.useState(0)
    const [tab, setTab] = React.useState('1');
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };



    const FetchGetMyOffer = async () => {
        let response = await GetService(GetMyOffer)
        let buyOffer = []
        let saleOffer = []

        response.data.Data.map(function (rest) {
            rest.Type === 1 ? buyOffer.push(rest) : saleOffer.push(rest)
        })
        setBuyList(buyOffer)
        setSaleList(saleOffer)
    }

    useEffect(() => {
        FetchGetMyOffer()
    }, [refreshCount])

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    return (
        <Container>
            <Button
                variant="contained"
                color="success"
                fullWidth
                state={{ title: 'ثبت پیشنهاد من' }}
                component={NavLink}
                to="/profile/submit-offer"
                className="btn"
            >
                ثبت پیشنهاد من
            </Button>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} centered variant="fullWidth">
                            <Tab label="خرید" value="1" />
                            <Tab label="فروش" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: '1.5rem 0' }}>
                        <List>
                            {
                                buyList === undefined || buyList === null ? null : buyList.map((content, i) => <UserOfferList refresh={refreshCount} key={i} data={content} />)
                            }
                        </List>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: '1.5rem 0' }}>
                        <List>
                            {
                                saleList === undefined || saleList === null ? null : saleList.map((content, i) => <UserOfferList refresh={refreshCount} key={i} data={content} />)
                            }
                        </List>
                    </TabPanel>
                </TabContext>
            </Box>

        </Container >
    )
}  