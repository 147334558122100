import React from "react";
import { Box } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moment from 'moment';

export function SendMsg(props) {
    return (
        <Box sx={{ py: 2 }}>
            <Box sx={{ marginRight: '2rem', background: '#426BA2', borderRadius: '16px 16px 16px 0', padding: '0.5rem 1rem ' }} >
                <p style={{ fontSize: '14px', color: ' white' }}>{props.msg}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row-reverse' }}>
                    <span style={{ fontSize: '10px', color: 'white', textAlign: 'left' }}>
                        {moment(props.date).format("HH:mm - YYYY/MM/DD")}
                    </span>
                    {props.IsSeen ?
                        <DoneAllIcon sx={{ color: 'white', fontSize: '14px' }} />
                        :
                        <DoneIcon sx={{ color: 'white', fontSize: '14px' }} />
                    }
                </div>
            </Box>
        </Box>
    )
}

export function RecieveMsg(props) {
    return (
        <Box sx={{ py: 2 }}>
            <Box sx={{ marginLeft: '2rem', background: '#FFFFFF', borderRadius: '16px 16px 0 16px', padding: '0.5rem 1rem ' }}>
                <p style={{ fontSize: '14px', color: '#426BA2' }}>{props.msg}</p>
                <div>
                    <span style={{ fontSize: '10px', color: '#426BA2', textAlign: 'right', display: 'block' }}>
                        {moment(props.date).format("HH:mm - YYYY/MM/DD")}
                    </span>
                </div>
            </Box>
        </Box>
    )
}