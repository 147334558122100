import { setLoginInfo, setAuthLoading } from '../redux/reducer'
import { store } from '../redux/store'
import Cookies from 'js-cookie'
import { ExtendTokenURL } from '../config'
import { PostService } from '../services/Fetch'


export const LoginService = async () => {




    const LoginFn = async () => {
        const LoginData = Cookies.get('loginData') ? JSON.parse(Cookies.get('loginData')) : null;
        if (LoginData) { // if Login Data Exist 
            let response = await PostService(
                ExtendTokenURL,
                { "rtoken": LoginData.renewalToken },
                {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + LoginData.accessToken
                }
            )
            if (response) {
                Cookies.set('loginData', JSON.stringify(response), { expires: 14 }) // 1/24 = 1 hour
                store.dispatch(setLoginInfo(response))
                store.dispatch(setAuthLoading(true))
                return true
            } else {
                Cookies.remove('loginData')
                store.dispatch(setLoginInfo(null))
                store.dispatch(setAuthLoading(true))
                return false
            }
        } else {
            Cookies.remove('loginData')
            store.dispatch(setLoginInfo(null))
            store.dispatch(setAuthLoading(true))
            return false
        }
    }

    LoginFn()

    setInterval(function () {
        LoginFn()
    }
        , 3000000); //60 sec * 50  = 50 min => 3000000

}

export default LoginService