import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MyRouter from './MyRouter'
import BottomNavigation from './BottomNavigation'
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import Box from '@mui/material/Box';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { Link, Routes, Route, useNavigate,  } from 'react-router-dom';
import Loading from './components/loading'
import SnackBar from './components/Snackbar'

const theme = createTheme({
  typography: {
    fontFamily: 'YekanBakh-Light',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'YekanBakh-Light';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: local('YekanBakh-Light'), url('../public/fonts/YekanBakh-Light.ttf') format('truetype');
        }
      `,
    },
  },
  palette: {
    cancel: {
      main: '#D3D3D3',
      contrastText: '#1976d2',
    },
  },

  direction: 'rtl',
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});



export default function App() {

  document.dir = 'rtl';
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Box sx={{ fontFamily: 'YekanBakh-Light', pb: '95px', pt : '76px' }}>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <Loading />
              <SnackBar />
              <MyRouter />
            </Suspense>

            <BottomNavigation />
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
}



