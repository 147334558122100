
import React, { useEffect } from "react";
import Container from '../components/Container'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import SetPageTitle from "../utils/SetPageTitle";
import Skeleton from '@mui/material/Skeleton';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 25,
    },
}));

const liStyle = {
    padding: '20px 0 '
}

const SkeletonTemplate = () => {
    return (
        <Skeleton animation="wave" variant="rounded" height={62} sx={{ width: '100%', marginBottom: '10px' }} />
    )
}

export default function MessageList(props) {
    const UserInfo = useSelector(state => state.state.userInfo)
    const ChatList = useSelector(state => state.state.chatList)

    const iconStyle = {
        borderRadius: '12px',
        padding: '4px',
        color: '#838383'
    }

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    return (
        <Container>
            <div className="Messages">
                <List>
                    {ChatList ?
                        ChatList.map(function (item, index) {
                            let targetUserId = item.ReciverId !== UserInfo?.UserId ? item.ReciverId : item.SenderId;
                            let DisplayName = item.ReciverId !== UserInfo?.UserId ? item.ReciverName : item.SenderName
                            return (
                                <React.Fragment key={index}>
                                    <ListItem disablePadding >
                                        <ListItemButton style={liStyle} component={NavLink} to="/messages/detail" state={{ ChatId: item.ChatId, targetUserId: targetUserId, DisplayName: DisplayName }}>

                                            <ListItemIcon>
                                                <PersonOutlinedIcon style={iconStyle} />
                                            </ListItemIcon>
                                            <ListItemText primary={DisplayName} />
                                            <StyledBadge badgeContent={item.UnreadMessages} color="error" max={999}>
                                                <ChatOutlinedIcon style={iconStyle} sx={{ padding: 0 }} />
                                            </StyledBadge>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })

                        :
                        <>
                            <SkeletonTemplate />
                            <SkeletonTemplate />
                            <SkeletonTemplate />
                        </>
                    }

                </List>
            </div>
        </Container>
    )
}

