import React, { useEffect, useRef, useState } from 'react';
import {
    Routes,
    Route,
    useLocation,
    useNavigate
} from 'react-router-dom';
import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import VerifyEmail from './pages/VerifyEmail'
import SubmitNewInformation from './pages/SubmitNewInformation'
import Profile from './pages/Profile'
import MyOffers from './pages/MyOffers'
import SubmitNewOffer from './pages/SubmitNewOffer'
import ExchangeOffers from './pages/ExchangeOffers'
import Messages from './pages/Messages'
import MessageList from './pages/MessageList'
import MessageDetail from './pages/MessageDetail'
import SpecialBuyPrice from './pages/SpecialBuyPrice';
import ProtectedRoute from './utils/protectedRoute'
import UnProtectedRoute from './utils/UnProtectedRoute'
import { UserRoute, ExchangeRoute } from './utils/ProtectedRoles'
import PWAInstaller from './utils/PWAInstaller'
import BackButton from './BackButton'
import { useSelector } from 'react-redux'
import { LoginService } from './services/LoginService'
import { NotificationService } from './services/NotificationService'
import GetUserInfoService from './services/GetUserInfoService';
import BuyPrice from './pages/BuyPrice';
import SpecialSalePrice from './pages/SpecialSalePrice';
import SalePrice from './pages/SalePrice';
import { useDispatch } from 'react-redux'
import { setChatNotification, setLoadingDecrease, setLoadingIncrease, setChatList, setOfferCount } from './redux/reducer'
import NoConnection from './pages/Noconnection';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Notifications from './pages/Notifications';
import ForgotPass from './pages/ForgotPass';
import ForgotPassSubmitEmail from './pages/ForgotPassSubmitEmail';
import SubmitNewSpecialBuy from './pages/SubmitNewSpecialBuy';
import SubmitNewSpecialSale from './pages/SubmitNewSpecialSale';
import { AllOffersForExchange } from './config'
import { GetService } from './services/Fetch'


export default function MyRouter() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const LoginInfo = useSelector(state => state.state.loginInfo)
    const isMounted = useRef(false);
    const UserRoles = useSelector(state => state.state.roles)

    //signalR connection
    const [connection, setConnection] = useState(null);
    const [connection2, setConnection2] = useState(null);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl('https://pwa.irguild.com/hubs/chat')
            // .withUrl('http://localhost:5065/hubs/chat')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

    }, []);

    useEffect(() => {
        if (connection && LoginInfo) {
            dispatch(setLoadingIncrease())
            connection.start()
                .then(result => {
                    setConnection2(connection)
                    connection.on('Join', () => {
                        connection.send('Join', connection.connectionId, LoginInfo.userId, LoginInfo.displayName);
                    });
                    connection.on('GetChatList', () => {
                        connection.send('GetChatList', LoginInfo.accessToken);
                    });
                    connection.send('GetChatList', LoginInfo.accessToken);
                    connection.on('ChatList', res => {
                        dispatch(setChatList(res.data))
                        // setChatList(res.data)
                        notificationCounter(res.data)
                    });
                    dispatch(setLoadingDecrease())
                })
                .catch(e => {
                    dispatch(setLoadingDecrease())
                    console.log('Connection failed: ', e)
                });
        } else if (connection && !LoginInfo) {
            connection.stop()
        }
    }, [connection, LoginInfo]);


    const notificationCounter = (data) => {
        let counter = 0;
        data.map(function (item) {
            counter = counter + item.UnreadMessages
        })
        dispatch(setChatNotification(counter))
    }

    const onlineCheck = () => {
        window.navigator.onLine ? navigate('/') : navigate('/no-connection')
    }


    useEffect(() => {
        LoginService();
    }, [])

    useEffect(() => {
        if (LoginInfo) {
            GetUserInfoService()
            NotificationService()
            isMounted.current = true;
        }
    }, [LoginInfo])


    const FetchGetAllOffersForExchange = async () => {
        let response = await GetService(AllOffersForExchange)
        dispatch(setOfferCount(response.data.Data))
    }

    useEffect(() => {
        if (UserRoles.Exchange)
            FetchGetAllOffersForExchange()
    }, [location])

    return (
        <>
            <Routes>
                <Route children="/*" element={<PWAInstaller />}>

                    < Route path="/" element={< Home title="خانه" />} />

                    < Route children="/*" element={< BackButton />}>
                        <Route children="/*" element={<UnProtectedRoute />}>
                            <Route path="/login" element={<Login title="ورود" />} />
                            <Route path="/register" element={<Register title="ثبت نام" />} />
                            <Route path="/verify-email" element={<VerifyEmail title="تایید ایمیل" />} />
                            <Route path="/submit-code" element={<ForgotPass title="ورود" />} />
                            <Route path="/forgot" element={<ForgotPassSubmitEmail title="فراموشی کلمه عبور" />} />
                            <Route path="/submit-new-information" element={<SubmitNewInformation title="ثبت مشخصات" />} />
                        </Route>

                        <Route children="/*" element={<ProtectedRoute />}>
                            <Route children="/profile">
                                <Route path="/profile" element={<Profile title="حساب کاربری" />} />

                                <Route children="/*" element={<UserRoute />}>
                                    <Route path="/profile/my-offers" element={<MyOffers title="پیشنهادات من" />} />
                                    <Route path="/profile/submit-offer" element={<SubmitNewOffer title="ثبت پیشنهاد من" />} />
                                </Route>

                                <Route children="/*" element={<ExchangeRoute />}>
                                    <Route path="/profile/special-buy-price" element={<SpecialBuyPrice title="قیمت خرید ویژه" />} />
                                    <Route path="/profile/special-sale-price" element={<SpecialSalePrice title="قیمت فروش ویژه" />} />
                                    <Route path="/profile/buy-price" element={<BuyPrice title="قیمت خرید" />} />
                                    <Route path="/profile/sale-price" element={<SalePrice title="قیمت فروش" />} />
                                    <Route path="/profile/submit-special-buy" element={<SubmitNewSpecialBuy title="ثبت خرید ویژه" />} />
                                    <Route path="/profile/submit-special-sale" element={<SubmitNewSpecialSale title="ثبت فروش ویژه" />} />
                                    <Route path="/profile/exchange-offers" element={<ExchangeOffers title="پیشنهادات" connection={connection2} />} />
                                </Route>

                            </Route>
                            <Route children="/messages/*" element={<Messages />}>
                                <Route path="/messages/list" element={<MessageList title="پیامهای من" />} />
                                <Route path="/messages/detail" element={<MessageDetail connection={connection2} />} />
                            </Route>

                            <Route path="/notifications" element={<Notifications title="اعلان ها" />} />
                        </Route>

                    </Route >
                    <Route path="/no-connection" element={<NoConnection title="خطا در دسترسی به اینترنت" />} />
                    <Route path="*" element={<NoMatch />} />
                </Route >
            </Routes >
        </>
    );
}



function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
            </p>
        </div>
    );
}

