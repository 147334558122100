import React, { useEffect } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import Button from '@mui/material/Button';
import { PostService } from "../services/Fetch";
import { SendEmailVerify, } from "../config";
import { useDispatch } from "react-redux";
import {  setSnackBarStatus } from "../redux/reducer";
import Container from "../components/Container";
import Cookies from "js-cookie";
import { Grid } from "@mui/material";
import SetPageTitle from "../utils/SetPageTitle";
import { Button, } from "../components/inputs/Button";

export default function ForgotPassSubmitEmail(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loc = useLocation()
    const { handleSubmit, control } = useForm({
        defaultValues: {
        }
    });

    const FetchVerifyEmail = async (rest) => {
        let response = await PostService(SendEmailVerify, {
            Email: rest,
            EmailTemplateHTML:"[0]",
            Type: 'ForgetPassword'
        }, false)
        if(response?.Data) {
            if(response?.StatusCode === 200) {
                navigate('/submit-code', {state : {Email : rest, Data: response.Data}})
                Cookies.set('ExpireDate', Date(), { expires: 1 / 24 }) // 1/360 = 2 Min
            } else {
                dispatch(setSnackBarStatus({ state: true, type: 'error', msg: response.Message }))
            }
        }
    }
    const onSubmit = (data) => {
        FetchVerifyEmail(data.email)
    }

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

  return (
    <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="email"
                control={control}
                defaultValue = {loc.state.email}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <Grid container xs={12} sx={{height: '430px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <TextField
                                variant="outlined"
                                size="large"
                                className="TextField align--left"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                label="ایمیل"
                                placeholder="example@gmail.com"
                                InputProps={{
                                    style: {
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        // direction: 'ltr'
                                    },
                                    endAdornment: (
                                        <InputAdornment sx={{padding: '0 0.5rem'}} position="center">
                                          <EmailOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                            <Button label="مرحله بعد" type='submit' />
                        </Grid>
                    </>
                )}
                rules={{ required: true }}
            />

        </form>
    </Container>
  );
}
