import React from "react"
import MuiButton from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

export const Button = React.forwardRef(function Input({
  label,
  variant = 'contained',
  color = "primary",
  fullWidth = true,
  type,
  disabled = false,
  ...props
}, ref) {
  return (

    <MuiButton {...props} ref={ref} disabled={disabled} type={type} variant={variant} color={color} fullWidth={fullWidth} className="btn" >
      {label}
    </MuiButton >

  );
});


export const NavButton = ({
  label,
  to = { to },
  variant = 'contained',
  color = "primary",
  fullWidth = true,
  ...rest
}) => {
  return (
    <MuiButton {...rest} component={NavLink} to={to} variant={variant} color={color} fullWidth={fullWidth} className="btn">
      {label}
    </MuiButton>
  )
}
export const NavButtonExchangeRegister = ({
  label,
  variant = 'contained',
  color = "primary",
  fullWidth = true,
  ...rest
}) => {
  return (
    <MuiButton {...rest} variant={variant} color={color} fullWidth={fullWidth} className="btn">
      {label}
    </MuiButton>
  )
}
