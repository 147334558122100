import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Box } from "@mui/material";

export default function ExchangeBox(props) {
  return (
    <Box className='exchange--wrap' sx={{ borderRadius: '30px', border: '1px solid #E1E1E1', padding: '28px 20px' }}>
      <Box className='exchange--header' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CurrencyExchangeIcon sx={{ color: '#fff', backgroundColor: props.backgroundColor , padding: '4px', borderRadius: '100%' }} />
          <span style={{ color: '#426BA2', fontSize: '18px', fontWeight: '700', marginRight: '5px' }}>
            {props.title}
          </span>
        </Box>
        <span>
          (قیمت ها به تومان)
        </span>
      </Box>
      <Box className='exchange--items'>
        {props.children}
      </Box>
    </Box>
  )

}



