
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function BackButton() {

  const navigate = useNavigate();
  const pageTitle = useSelector(state => state.state.pageTitle)
  const currentLocation = useLocation()

  useEffect(() => {
    console.log()
  })
  const handleBack = () => {
    if(currentLocation.pathname == '/login') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <div className="BackButton">
        <button
          onClick={handleBack}
        ><EastIcon />
        </button>
        <span>
          {pageTitle}
          {/* {location?.state?.title} */}
        </span>
      </div>
      <Outlet />
    </>
  )
}  