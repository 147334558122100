import React from 'react';
import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const ChatInput = (props) => {

    const { handleSubmit, control, watch, reset } = useForm({});
    const Text = watch('Text')

    const onSubmit = (data) => {
        reset()
        props.sendMessage(data.Text)
    }



    return (
        <>
            <Box sx={{ position: 'fixed', bottom: '0px', left: 0, right: 0, borderTop: 'solid 1px #D3D3D3', borderRadius: '5px 5px 0 0 ', backgroundColor: '#fff' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ padding: '10px 10px 10px 30px' }}>
                        <Controller
                            name="Text"
                            control={control}
                            defaultValue={''}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    size="large"
                                    type="text"
                                    placeholder='پیام خود را وارد نمائید......'
                                    className="TextField"
                                    autoComplete='off'
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <IconButton aria-label="send" type='submit' disabled={!!Text ? false : true}>
                                                    <SendIcon color={!!Text ? 'primary' : 'disable'} sx={{ transform: 'rotate(180deg)' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        border: 'none !important'
                                    }}
                                    multiline
                                    maxRows={2}

                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                            rules={{ required: 'وارد کردن ایمیل اجباری است ' }}
                        />
                    </Box>

                </form>
            </Box>
        </>
    )
};

export default ChatInput;
