import { setUserInfo } from '../redux/reducer'
import { store } from '../redux/store'
import { GetUserInfo } from '../config'
import { GetService } from './Fetch'


export const GetUserInfoService = async () => {
  let response = await GetService(GetUserInfo)
  store.dispatch(setUserInfo(response.data.Data))
}
export default GetUserInfoService