
import React, { useEffect } from "react";
// import logo from '/images/logo.svg';
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { PostService, GetService } from '../services/Fetch'
import {
    GetSpecialExchangesSalePrice,
    GetSpecialExchangesBuyPrice,
    GetUserOffers,
    CreateChat,
    GetExchangeInfo
} from '../config'
import SpecialExchangeBox from '../components/SpecialExchangeBox'
import SpecialExchangeContent from '../components/SpecialExchangeContent'
import UserOfferBox from '../components/UserOfferBox'
import UserOfferContent from '../components/UserOfferContent'
import Skeleton from '@mui/material/Skeleton';
import Container from '../components/Container'
import SetPageTitle from "../utils/SetPageTitle";



const ExchangeSkeletonTemplate = () => {
    return (
        <Skeleton animation="wave" variant="rounded" height={58} sx={{ width: '100%', marginBottom: '10px' }} />
    )
}


const SpecialTemplate = () => {
    const navigate = useNavigate();
    const [specialExchangesSalePrice, setSpecialExchangesSalePrice] = React.useState()
    const [specialExchangesBuyPrice, setSpecialExchangesBuyPrice] = React.useState()
    const [noItembuy, setNoItembuy] = React.useState(true)
    const [noItemsale, setNoItemsale] = React.useState(true)

    const spBuyFlag = []
    const spSaleFlag = []

    const FetchChat = async (UserId, DisplayName) => {
        let response = await PostService(CreateChat, { To: UserId })
        navigate("/messages/detail", {
            state: {
                ChatId: response.Data.Id,
                targetUserId: UserId,
                DisplayName: DisplayName
            }
        });
    }

    const FetchGetSpecialExchangesSalePrice = async () => {
        let response = await PostService(GetSpecialExchangesSalePrice, {})
        setSpecialExchangesSalePrice(response.Data);
        response.Data.forEach((val, i) => {
            if(val.SpecialSale != null || val.SpecialSale != undefined) {
                spSaleFlag.push(i)
            }
            if(spSaleFlag.length == 0) {
                setNoItemsale(false)
            } else {
                setNoItemsale(true)
            }
        })
    }
    const FetchGetSpecialExchangesBuyPrice = async () => {
        let response = await PostService(GetSpecialExchangesBuyPrice, {})
        setSpecialExchangesBuyPrice(response.Data);
        response.Data.forEach((val, i) => {
            if(val.SpecialBuy != null || val.SpecialBuy != undefined) {
                spBuyFlag.push(i)
            }
            if(spBuyFlag.length == 0) {
                setNoItembuy(false)
            } else {
                setNoItembuy(true)
            }
        })
    }

    useEffect(() => {
        FetchGetSpecialExchangesSalePrice()
        FetchGetSpecialExchangesBuyPrice()
    }, [])
    return (
        <>
            <Box sx={{ mt: 5 }}>
                <SpecialExchangeBox sx={{position: 'relative'}} title="خرید ویژه">        
                    {specialExchangesBuyPrice ?
                        (
                            noItembuy ? 
                            specialExchangesBuyPrice.map(function (item, index) {
                                if (item.SpecialBuy) {
                                    return (
                                        <SpecialExchangeContent key={index} data={item} method='buy' FetchChat={FetchChat} />
                                    )
                                }
                            })
                            :
                            <h3 style={{textAlign: 'center', color: '#c4c4c4', fontWeight: 'normal'}}>در حال حاضر خرید ویژه ای وجود ندارد</h3>

                        )
                        :
                        <>
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                        </>
                    }

                </SpecialExchangeBox>
            </Box>
            <Box sx={{ mt: 5 }}>
                <SpecialExchangeBox title="فروش ویژه">
                    {specialExchangesSalePrice ?
                        (
                            noItemsale ? 
                            specialExchangesSalePrice.map(function (item, index) {
                                if (item.SpecialSale) {
                                    return (
                                        <SpecialExchangeContent key={index} data={item} method='sale' FetchChat={FetchChat} />
                                    )
                                }
                            })
                            :
                            <h3 style={{textAlign: 'center', color: '#c4c4c4', fontWeight: 'normal'}}>در حال حاضر فروش ویژه ای وجود ندارد</h3>

                        )
                        :
                        <>
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                        </>
                    }

                </SpecialExchangeBox>
            </Box>
        </>
    )
}

const UserOfferTemplate = () => {
    const [buyOffers, setBuyOffers] = React.useState()
    const [saleOffers, setSaleOffers] = React.useState()
    const [exchangeInfo, setExchangeInfo] = React.useState()
    const [noBuyOffer, setNoBuyOffer] = React.useState(false)
    const [noSaleOffer, setNoSaleOffer] = React.useState(false)

    const FetchGetExchangeId = async () => {
        let response = await GetService(GetExchangeInfo, {})
        let temp = await response.data
        if (temp?.Data) {
            setExchangeInfo(temp.Data.Id)
        }
    }
    const FetchGetUserOffers = async () => {
        let response = await GetService(GetUserOffers, {})
        let buyList = []
        let saleList = []
        response.data.Data.map(function (item) {
            //buy type = 1 , sale type = 2
            item.Type === 1 ?
                buyList.push(item)
                :
                saleList.push(item)

                if(buyList.length == 0) {
                    setNoBuyOffer(false)
                } else {
                    setNoBuyOffer(true)
                }

                if(saleList.length == 0) {
                    setNoSaleOffer(false)
                } else {
                    setNoSaleOffer(true)
                }
        })

        setBuyOffers(buyList);
        setSaleOffers(saleList);
    }


    useEffect(() => {
        FetchGetExchangeId()
        FetchGetUserOffers()
    }, [])
    return (
        <>
            <Box sx={{ mt: 5 }}>
                <UserOfferBox title="پیشنهاد خرید کاربران" backgroundColor='#00BC1E'>
                    {buyOffers ?
                        (
                            noBuyOffer?
                                buyOffers.map(function (item, index) {
                                    return (
                                        <UserOfferContent key={index} data={item} exid={exchangeInfo} />
                                    )
                                })
                            :
                            <h3 style={{textAlign: 'center', color: '#c4c4c4', fontWeight: 'normal'}}>در حال حاضر پیشنهاد خریدی وجود ندارد</h3>

                        )
                        :
                        <>
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                        </>
                    }

                </UserOfferBox>
            </Box>
            <Box sx={{ mt: 5 }}>
                <UserOfferBox title="پیشنهاد فروش کاربران" backgroundColor='#DB0B0B'>
                    {saleOffers ?
                        (
                            noSaleOffer?
                                saleOffers.map(function (item, index) {
                                    return (
                                        <UserOfferContent key={index} data={item} exid={exchangeInfo} />
                                    )
                                })
                            :
                            <h3 style={{textAlign: 'center', color: '#c4c4c4', fontWeight: 'normal'}}>در حال حاضر پیشنهاد فروشی وجود ندارد</h3>

                        )
                        :
                        <>
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                            <ExchangeSkeletonTemplate />
                        </>
                    }

                </UserOfferBox>
            </Box>
        </>
    )
}

export default function ExchangeOffers(props) {

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    return (
        <Container>
            <div className="exchange--offers">



                <SpecialTemplate />
                <UserOfferTemplate />
            </div>

        </Container >

    )
}  