import { useSelector, useDispatch } from 'react-redux'
import SnackbarMui from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { setSnackBarStatus } from '../redux/reducer'

export default function Snackbar() {
  const dispatch = useDispatch()

  const snackBar = useSelector(state => state.state.snackBar)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackBarStatus({ state: false, type: 'success', msg: '' }))
  };

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={snackBar.state}
      autoHideDuration={6000}
      onClose={handleClose}
      message="Note archived"
    >
      <Alert
        severity={snackBar?.type}
      >{snackBar?.msg}</Alert>
    </SnackbarMui>
  )
}



