import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { NavLink, Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useSelector } from "react-redux";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { padding } from "@mui/system";
import { useLocation } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 25,
  },
}));

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState();
  const LoginInfo = useSelector((state) => state.state.loginInfo);
  const UserRoles = useSelector((state) => state.state.roles);
  const chatNotification = useSelector((state) => state.state.chatNotification);
  const notificationsCount = useSelector(
    (state) => state.state.notificationsCount
  );
  const offerCount = useSelector((state) => state.state.offerCount);
  const [activeNav, setActiveNav] = React.useState(true);
  const myLocation = useLocation();

  let currentPath = myLocation.pathname;
  // const pathArr = [
  //   "/profile/buy-price",
  //   "/profile/sale-price",
  //   "/profile/submit-special-buy",
  //   "/profile/submit-special-sale",
  //   "/profile/submit-offer",
  //   "/login",
  //   "/register",
  //   "/verify-email",
  //   "/submit-code",
  //   "/forgot",
  //   "/submit-new-information",
  // ];

  const ChatBadgeTemplate = () => {
    return (
      <StyledBadge badgeContent={chatNotification} color="error" max={999}>
        <ChatOutlinedIcon sx={{ padding: 0 }} />
      </StyledBadge>
    );
  };

  const NotificationBadgeTemplate = () => {
    return (
      <StyledBadge badgeContent={notificationsCount} color="error" max={999}>
        <NotificationsActiveOutlinedIcon sx={{ padding: 0 }} />
      </StyledBadge>
    );
  };

  const OfferBadgeTemplate = () => {
    return (
      <StyledBadge badgeContent={offerCount} color="error" max={999}>
        <LocalOfferOutlinedIcon sx={{ padding: 0 }} />
      </StyledBadge>
    );
  };

  useEffect(() => {
    switch(myLocation.pathname) {
      case '/profile/buy-price' :
        setActiveNav(false)
        break
      case '/profile/sale-price' :
        setActiveNav(false)
        break
      case '/profile/submit-special-buy' :
        setActiveNav(false)
        break
      case '/profile/submit-special-sale' :
        setActiveNav(false)
        break
      case '/profile/submit-offer' :
        setActiveNav(false)
        break
      case '/login' :
        setActiveNav(false)
        break
      case '/register' :
        setActiveNav(false)
        break
      case '/verify-email' :
        setActiveNav(false)
        break
      case '/submit-code' :
        setActiveNav(false)
        break
      case '/forgot' :
        setActiveNav(false)
        break
      case '/submit-new-information' :
        setActiveNav(false)
        break
      default:
        setActiveNav(true)
    }

    // pathArr.forEach((val, i) => {
    //   console.log(val);
    //   console.log(currentPath)
    //   console.log(currentPath == val)
    //   if (currentPath == val) {
    //     setActiveNav(false);
    //   } else {
    //     setActiveNav(true);
    //   }
    // });
  }, [currentPath]);

  return (
    <Box
      sx={{ width: 500 }}
      display={activeNav ? "block" : "none"}
      className="bottom--navigation"
    >
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "30px 30px 0 0",
          zIndex: 20,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          sx={{
            "& .MuiBottomNavigationAction-root": {
              padding: "0 0 0 0",
              color: "#838383",
              minWidth: "unset",
            },
            "& .Mui-selected": {
              color: "unset",
            },
            minHeight: "68px",
            padding: "0 5px",
          }}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className="bottom--navigation"
        >
          {!LoginInfo ? (
            <BottomNavigationAction
              state={{ title: "ورود" }}
              component={Link}
              to="/login"
              className="notification"
              label="ورود"
              icon={<PersonOutlinedIcon />}
            />
          ) : (
            <BottomNavigationAction
              state={{ title: "حساب کاربری" }}
              component={Link}
              to="/profile"
              className="notification"
              label="حساب کاربری"
              icon={<PersonOutlinedIcon />}
            />
          )}
          <BottomNavigationAction
            component={Link}
            to={
              UserRoles.Exchange
                ? "/profile/exchange-offers"
                : "/profile/my-offers"
            }
            className="notification"
            label="پیشنهادات"
            icon={
              UserRoles.Exchange ? (
                <OfferBadgeTemplate />
              ) : (
                <LocalOfferOutlinedIcon />
              )
            }
          />
          <BottomNavigationAction
            component={Link}
            to="/"
            className="home"
            label="خانه"
            icon={<HomeOutlinedIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/notifications"
            className="notification"
            label="اعلان ها"
            icon={<NotificationBadgeTemplate />}
          />
          <BottomNavigationAction
            component={Link}
            to="/messages/list"
            className="notification"
            label="پیام ها"
            icon={<ChatBadgeTemplate />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
