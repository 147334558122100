import { setNotification } from '../redux/reducer'
import { store } from '../redux/store'
import Cookies from 'js-cookie'
import { PostService, GetService } from './Fetch'
import { GetNotification } from "../config";


export const NotificationService = async () => {

    let response = await GetService(GetNotification)
    store.dispatch(setNotification(response.data.Data))

}

export default NotificationService