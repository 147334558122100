
import React, { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { PostService } from '../services/Fetch'
import { SendEmailVerify, VerifyEmailUrl } from '../config'
import { useDispatch } from 'react-redux'
import { setUserIdForRegister, setSnackBarStatus } from '../redux/reducer'
import Container from '../components/Container'
import Cookies from 'js-cookie'
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import SetPageTitle from "../utils/SetPageTitle";
import { Button } from "../components/inputs/Button"




export default function VerifyEmail(props) {
    var t1 = new Date(Cookies.get('ExpireDate'));
    var t2 = new Date();
    var dif = (t2.getTime() - t1.getTime()) / 1000;

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [snackbar, setSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState();
    const [counter, setCounter] = React.useState(120 - Math.round(dif));
    const tick = useRef(120 - Math.round(dif))
    const [start, setStart] = React.useState(0);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
        }
    });

    const onSubmit = async (data) => {
        let compiledData = {
            VerifyCode: data.input1 + data.input2 + data.input3 + data.input4,
            EncryptCode: Cookies.get('EncryptCode'),
            Email: Cookies.get('Email')
        }

        let response = await PostService(VerifyEmailUrl, compiledData, false)
        if (response?.StatusCode === 200) {
            dispatch(setUserIdForRegister(response.Data))
            navigate(`/submit-new-information`)
        } else {
            dispatch(setSnackBarStatus({ state: true, type: 'error', msg: response.Data }))
        }
    };

    const ResendCode = async () => {
        if (Cookies.get('Email')) {
            reset({
                input1: '',
                input2: '',
                input3: '',
                input4: ''
            })
            const compiltedData = {
                Email: Cookies.get('Email'),
                EmailTemplateHTML: "[0]",
                Type: "Register"
            }
            let response = await PostService(SendEmailVerify, compiltedData, false)
            if (response?.StatusCode === 200) {
                Cookies.set('EncryptCode', response.Data, { expires: 1 / 360 }) // 1/360 = 2 Min
                Cookies.set('Email', Cookies.get('Email'), { expires: 1 / 24 }) // 1/24 = 1 H
                Cookies.set('ExpireDate', Date(), { expires: 1 / 24 }) // 1/360 = 2 Min
                setCounter(120)
                tick.current = 120
                setStart(start + 1)
                // navigate(`/verify-email`)
            } else {
                setSnackbarMsg(response?.Data)
                setSnackbar(true);
            }
        } else {
            navigate(`/register`)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((counter) => counter - 1)
            tick.current = --tick.current
            if (tick.current <= 0) clearInterval(interval)
        }, 1000)
        return () => clearInterval(interval);
        // inter()
    }, [start])


    const input1 = useRef();
    const input2 = useRef();
    const input3 = useRef();
    const input4 = useRef();

    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])

    const DisablePaste = (e) => {
        let pasteValue = (e.clipboardData || window.clipboardData).getData("text")
        if (!isNaN(parseInt(pasteValue)) && pasteValue.length === 4) {
            const SplitedCode = pasteValue.split('')
            e.preventDefault();
            reset({
                input1: SplitedCode[0],
                input2: SplitedCode[1],
                input3: SplitedCode[2],
                input4: SplitedCode[3]
            })
            submitBtn.current.click()
        } else {
            e.preventDefault();
        }
    }

    const submitBtn = useRef(null);

    return (
        <Container>
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: 'calc(100vh - 235px)' }}>
                <Grid container spacing={2} direction="column" justifyContent="space-between" sx={{ height: '100%', flexWrap: 'nowrap' }}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Grid container spacing={2} justifyContent="center" flexDirection='row-reverse'>
                            <Grid item xs={3} >
                                <Controller
                                    name="input1"
                                    control={control}
                                    // defaultValue = {''}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            inputRef={input1}
                                            variant="outlined"
                                            size="large"
                                            type="number"
                                            className="TextField align--center"
                                            fullWidth
                                            value={value}
                                            width={120}
                                            onChange={(newValue) => {
                                                onChange(newValue.nativeEvent.data)
                                                if (newValue.nativeEvent.data)
                                                    input2.current.focus()
                                            }}
                                            onPaste={DisablePaste}
                                            InputProps={{
                                                style: {
                                                    fontSize: 18,
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: ' ' }}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    name="input2"
                                    control={control}
                                    // defaultValue = {''}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            inputRef={input2}
                                            variant="outlined"
                                            size="large"
                                            type="number"
                                            // autoComplete="true"
                                            className="TextField align--center"
                                            fullWidth
                                            value={value}
                                            onChange={(newValue) => {
                                                onChange(newValue.nativeEvent.data)
                                                if (newValue.nativeEvent.data)
                                                    input3.current.focus()
                                            }}
                                            onPaste={DisablePaste}
                                            InputProps={{
                                                style: {
                                                    fontSize: 18,
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: ' ' }}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    name="input3"
                                    control={control}
                                    // defaultValue = {''}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            inputRef={input3}
                                            variant="outlined"
                                            size="large"
                                            type="number"
                                            className="TextField align--center"
                                            fullWidth
                                            value={value}
                                            width={120}
                                            onChange={(newValue) => {
                                                onChange(newValue.nativeEvent.data)
                                                if (newValue.nativeEvent.data)
                                                    input4.current.focus()
                                            }}
                                            onPaste={DisablePaste}
                                            InputProps={{
                                                style: {
                                                    fontSize: 18,
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: ' ' }}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    name="input4"
                                    control={control}
                                    // defaultValue = {''}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            inputRef={input4}
                                            variant="outlined"
                                            size="large"
                                            type="number"
                                            className="TextField align--center"
                                            fullWidth
                                            value={value}
                                            width={120}
                                            onChange={(newValue) => {
                                                onChange(newValue.nativeEvent.data)
                                                submitBtn.current.click()
                                                if (newValue.nativeEvent.data)
                                                    input4.current.blur()
                                            }}
                                            onPaste={DisablePaste}
                                            InputProps={{
                                                style: {
                                                    fontSize: 18,
                                                    fontWeight: 'bold'
                                                }
                                            }}

                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                    rules={{ required: ' ' }}
                                />
                            </Grid>

                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                            {counter > 0 ?
                                <span>
                                    {counter} &nbsp; ثانیه تا ارسال مجدد
                                </span>
                                :
                                null
                            }

                        </Box>
                        {/* <Timer /> */}
                        {/* <PomView /> */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            {counter <= 0 ?
                                <Button label="ارسال مجدد کد" variant="outlined" color="primary" className="btn" onClick={ResendCode}>
                                    ارسال مجدد کد
                                </Button>
                                :
                                null
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        {/* <Button ref={submitBtn} disabled={counter <= 0 ? true : false} type="submit" variant="contained" color="primary" fullWidth className="btn">
                            تایید
                        </Button> */}

                        <Button label='تایید' type='submit' ref={submitBtn} />
                        {/* {counter > 0 ?
                            <Button disabled={counter <= 0 ? true : false} type="submit" variant="contained" color="primary" fullWidth className="btn">
                                تایید
                            </Button>
                            :
                            null
                        } */}
                    </Grid>
                </Grid>
            </form>
        </Container >
    )
}  