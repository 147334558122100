import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NumberWithCommas from '../components/NumberWithCommas'

export default function ExchangeContent(props) {
 
  const sendUserId = (UserId, DisplayName) => {
    props.FetchChat(UserId, DisplayName)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={() => sendUserId(props.data.UserId, props.data.ExchangeName)}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="chat--icon"
          sx={{ fontWeight: 'normal !important' }}
        >
          <ChatOutlinedIcon sx={{ cursor: 'pointer' }} />
          {props.data.ExchangeName}
        </Grid>
      </Grid>
      <Grid
        item
        sx={{ minWidth: '200px', textAlign: 'right', fontWeight: 'normal !important' }}
        className="value"
      >
      {
        props.method == "buy" ? 
          (
            props.data.BuyPrice == 0 ? 
              (<span style={{paddingLeft: '1.625rem'}}>ارسال پیام</span>)
            :
            NumberWithCommas(props.data.BuyPrice)
          )
         :
          (         
            props.data.SalePrice == 0 ? 
            (<span style={{paddingLeft: '1.625rem'}}>ارسال پیام</span>)
            :
            NumberWithCommas(props.data.SalePrice)
          )
      }
      {
        props.method == "buy" ? 
          (
            props.data.BuyPrice == 0 ? 
              null
            :
            props.data.PriceDifference > 0 ? <NorthIcon fontSize="small" sx={{ color: '#1A9D2F' }} /> : null
          )
         :
          (         
            props.data.SalePrice == 0 ? 
              null
            :
            props.data.PriceDifference > 0 ? <NorthIcon fontSize="small" sx={{ color: '#1A9D2F' }} /> : null
          )
      }
      {
        props.method == "buy" ? 
          (
            props.data.BuyPrice == 0 ? 
              null
            :
            props.data.PriceDifference < 0 ? <SouthIcon fontSize="small" sx={{ color: '#DB0B0B' }} /> : null
          )
         :
          (         
            props.data.SalePrice == 0 ? 
              null
            :
            props.data.PriceDifference < 0 ? <SouthIcon fontSize="small" sx={{ color: '#DB0B0B' }} /> : null
          )
      }
      {
        props.method == "buy" ? 
          (
            props.data.BuyPrice == 0 ? 
              null
            :
            props.data.PriceDifference == 0 ? <NorthIcon fontSize="small" sx={{ color: 'transparent' }} /> : null
          )
         :
          (         
            props.data.SalePrice == 0 ? 
              null
            :
            props.data.PriceDifference == 0 ? <NorthIcon fontSize="small" sx={{ color: 'transparent' }} /> : null
          )
      }
      </Grid>
    </Grid>
  )

}


// data.PriceDifference
// data.PriceDifference
// data.PriceDifference