import React, { useEffect } from "react";
import { Box } from "@mui/system";
import SetPageTitle from "../utils/SetPageTitle";


export default function NoConnection(props) {
    useEffect(() => {
        SetPageTitle(props.title)
    }, [props.title])


    return (
        <Box sx={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundImage: 'linear-gradient(180deg, #003275 0%, #426BA2 52.08%, #003275 100%) !important', zIndex: '999999999999' }} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
            <img style={{ width: '250px', height: 'auto' }} src="/images/logo.svg" alt="Ir guild logo" />
            <h2 style={{ fontSize: '14px', marginTop: '6rem', color: '#FFFFFF' }}><span style={{ color: '#FF834F' }}>خطا</span> - عدم دسترسی به اینترنت</h2>
        </Box>
    )
}
