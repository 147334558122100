import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import NumberWithCommas from './NumberWithCommas'

export default function SpecialExchangeContent(props) {
  const sendUserId = (UserId, DisplayName) => {
    props.FetchChat(UserId, DisplayName)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ py: 1 }}
      onClick={() => sendUserId(props.data.ExchangeUserId, props.data.ExchangeName)}
    >
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="chat--icon"
        >
          <ChatOutlinedIcon />
          {props.data.ExchangeName}
        </Grid>
      </Grid>
      <Grid
        item
        sx={{ textAlign: 'right' }}
        className="value"
      >
        {props.method == "buy" ?
          NumberWithCommas(props.data.SpecialBuy.SpecialUnitBuyPrice) 
          :
          NumberWithCommas(props.data.SpecialSale.SpecialUnitPrice)
        }
        T
      </Grid>
      <Grid
        item
        sx={{ textAlign: 'right' }}
        className="value"
      >

        {props.method == "buy" ?
          NumberWithCommas(props.data.SpecialBuy.SpecialBuyPrice) + ' $'
          :
          NumberWithCommas(props.data.SpecialSale.SpecialPrice) + ' $'
        }

      </Grid>

    </Grid>
  )

}



