import { createSlice } from '@reduxjs/toolkit'

export const reducerFucntion = createSlice({
  name: 'state',
  initialState: {
    loginInfo: null,
    userInfo: null,
    roles: {
      Exchange: null,
      Limited: null
    },
    loadingCount: 0,
    authLoading: false,
    pageTitle: '',
    userIdForRegister: null,
    snackBar: {
      state: false,
      type: null,
      msg: ''
    },
    loginIsDone: false,
    chatList: null,
    chatNotification: null,
    notifications: null,
    notificationsCount: null,
    offerCount: null,
    msgApprove: {
      exist: false,
      type: '',
      amount: '',
      unitPrice: '',
    },
  },
  reducers: {
    setLoginInfo: (state, action) => {
      state.loginInfo = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      state.roles.Exchange = action.payload?.Roles.includes('Exchange') ? true : false
      state.roles.Limited = action.payload?.Roles.includes('Limited') ? true : false
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload
    },
    setUserIdForRegister: (state, action) => {
      state.userIdForRegister = action.payload
    },
    setSnackBarStatus: (state, action) => {
      state.snackBar = action.payload
    },
    setLoadingIncrease: (state, action) => {
      state.loadingCount = state.loadingCount + 1
    },
    setLoadingDecrease: (state, action) => {
      state.loadingCount = state.loadingCount - 1
    },
    setLoginIsDone: (state, action) => {
      state.loginIsDone = action.payload
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload
    },
    setChatList: (state, action) => {
      state.chatList = action.payload
    },
    setSeenChatList: (state, action) => {
      let count;
      state.chatList.map(function (item, index) {
        if (item.ChatId === action.payload) {
          count = state.chatList[index].UnreadMessages;
          state.chatList[index].UnreadMessages = 0
        }
      })
      state.chatNotification = state.chatNotification - count
    },

    setChatNotification: (state, action) => {
      state.chatNotification = action.payload
    },
    setNotification: (state, action) => {
      state.notifications = action.payload
      let counter = 0
      action.payload.map(function (item) {
        if (!item.IsSeen)
          counter = counter + 1
      })
      state.notificationsCount = counter
    },
    setMsgApprove: (state, action) => {
      state.msgApprove = action.payload
    },
    setOfferCount: (state, action) => {
      state.offerCount = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setOfferCount, setSeenChatList, setMsgApprove, setNotification, setChatNotification, setChatList, setAuthLoading, setLoginIsDone, setLoginInfo, setUserInfo, setPageTitle, setUserIdForRegister, setSnackBarStatus, setLoadingIncrease, setLoadingDecrease } = reducerFucntion.actions

export default reducerFucntion.reducer  